@import "../../../../assets/_var.scss";

.usercard {
  padding: 3rem 2rem;
  background-color: $themeClr2F;
  border-radius: 2.6rem;
  margin-top: -10rem;
  z-index: 1;
  position: relative;
  text-align: center;

  &_thumb {
    height: 17.7rem;
    width: 17.7rem;
    border-radius: 50%;
    margin-bottom: 2rem;
    object-fit: cover;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.08);
  }
  h6 {
    color: $white;
    margin-bottom: 0;
  }

  &_wlt_address {
    margin-bottom: 2rem;
    span {
      color: $gray98;
      font-size: 1.4rem;
    }

    button {
      margin-left: 0.5rem;
    }

    &_walletButton {
      &:hover {
        span {
          color: $white;
        }
      }
    }
  }

  ul {
    display: block;
    li {
      display: inline-block;
      margin: 2rem 1rem;
    }
  }
  hr {
    border-color: $borderClr2;
    border-width: 1px;
  }
  p,
  &_footer {
    color: $gray98;
    font-size: 1.4rem;
    margin-bottom: 0;
    word-break: break-word;
  }
  &_footer {
    margin-top: 2rem;
    &_btn {
      margin-top: 1.6rem !important;
      max-width: 17.6rem;
      width: 100%;
      margin: 0 auto;
    }
  }
  .commonBtn.btn-bordered {
    &:hover {
      span {
        color: $white;
      }
    }
  }
  .walletButton {
    min-width: 176px;
    margin-top: 10px;
    &::before {
      background: transparent linear-gradient(289deg, #f3d914 0%, #cd8511 100%)
        0% 0% no-repeat padding-box;
    }
  }
}
