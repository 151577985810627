@import "../../../assets/_var.scss";

.myitems {
    :global {
        table {
            tbody {
                tr {
                    height: 8rem;
                }
            }
        }
    }
}