@import '../../../assets/_var.scss';

.pass_handle {
    position: relative;

    input {
        padding-right: 6rem !important;
    }

    &_icon {
        cursor: pointer;
        position: absolute;
        right: 2rem;
        transform: translateY(-50%);
        top: 50%;

        svg {
            height: 1.6rem;
            width: 2.2rem;
            display: block;

            @media (max-width:1279px) {
                height: 1.4rem;
                width: 1.8rem;

            }
        }
    }
}