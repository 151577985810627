@import "../../../../assets/_var.scss";

.common_card {
  display: block;
  background-color: rgba($black, 0.3);
  height: 100%;
  overflow: hidden;
  border-radius: 2.5rem;
  @extend %transition;
  position: relative;
  z-index: 1;
  backdrop-filter: blur(5rem);
  display: flex;
  flex-direction: column;

  &_like {
    position: absolute;
    left: 2rem;
    top: 2rem;
    z-index: 1;
  }
  &_EditList {
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 1;

    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: "";
      width: 34px;
      height: 34px;
      background: transparent linear-gradient(180deg, #ad760e 0%, #d6ba0f 100%)
        0% 0% no-repeat padding-box;
      opacity: 0.2;
      border-radius: 50%;
    }
  }

  &_thumb {
    overflow: hidden;
    max-height: 27.5rem;
    min-height: 273px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 2.5rem;
      border-top-right-radius: 2.5rem;
      @extend %transition;
      min-height: 28rem;

      @media (max-width: 768px) {
        min-height: auto;
      }
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 2.5rem;
      border-top-right-radius: 2.5rem;
    }
  }

  &_head {
    padding: 1.7rem 2rem 1rem 2rem;

    h6 {
      color: $white900;
      font-weight: 600;
      margin-bottom: 1rem;
      display: block;
      margin: 0;
    }

    p {
      margin: 0;
      color: $gray98;
      font-size: 1.4rem;
      margin-top: 0.5rem;

      strong {
        color: $white;
        font-weight: 600;
      }
    }
  }

  &_price {
    padding: 0 2rem 1rem 2rem;
    position: relative;

    hr {
      margin: 0 0 1rem;
      border-color: rgba($gray98, 0.5);
    }

    &_left,
    &_right,
    span {
      color: $gray98;
      font-size: 1.2rem;
      font-weight: 500;
      display: block;
      padding-bottom: 0.5rem;
    }

    &_right {
      text-align: right;
    }

    h6 {
      font-size: 1.4rem;
      font-weight: bold;
      color: $white900;
      display: block;
      margin: 0;
    }
  }

  &_action {
    margin-top: auto;

    span {
      background-color: $black;
      font-size: 1.4rem;
      font-weight: 600;
      display: block;
      color: $white900;
      text-align: center;
      padding: 1.3rem;
      border-bottom-left-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &::before {
        content: "";
        height: 100%;
        width: 100%;
        z-index: -1;
        background: transparent
          linear-gradient(95deg, $yellowDark 0%, $yellow 100%) 0% 0% no-repeat
          padding-box;
        position: absolute;
        left: 0;
        top: 0;
        border-bottom-left-radius: 2.5rem;
        border-bottom-right-radius: 2.5rem;
        @extend %transition;
        opacity: 0;
      }
    }
  }

  &_gallery {
    padding: 1rem 1rem 2.4rem 2rem;

    > div {
      margin: 0 - 6px;

      > div {
        padding: 0 6px;
        min-width: 33%;
        max-width: 33%;
        height: 76px;
        img,
        video {
          width: 100%;
          border-radius: 1.7rem;
          object-fit: cover;
          height: 76px;
        }
      }
    }
  }

  @media (max-width: 1679px) {
    &_head {
      p {
        font-size: 1.2rem;
      }
    }

    &_price {
      h6 {
        font-size: 1.2rem;
      }
    }

    &_action {
      span {
        font-size: 1.2rem;
      }
    }
  }

  &:hover {
    .common_card {
      &_thumb {
        img {
          transform: scale(1.1);
        }
      }

      &_action {
        span {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    position: absolute;
    @extend %transition;
    pointer-events: none;
    max-width: 100%;
    opacity: 0.1;
    z-index: -1;
    background: transparent linear-gradient(97deg, $gray21 0%, $white900 100%)
      0% 0% no-repeat padding-box;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  &:hover {
    &::before {
      opacity: 0.2;
    }
  }
}
