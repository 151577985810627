@import "../../../../assets/_var.scss";

.authModalStyle {
  :global {
    .modal-content {
      max-width: 489px;
      .modal-header {
        margin-bottom: 4rem;

        &::before {
          position: absolute;
          content: "";
          left: 0px;
          display: block;
          width: 100%;
          height: 2px;
          border-bottom: 0.1rem solid $borderBottom;
          top: 9rem;
        }
      }
      ul {
        li {
          a {
            text-decoration: underline;
            font-size: 1.6rem;
            color: $white;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
          }
          &:not(:last-child) {
            margin-bottom: 1.2rem;
          }
        }
      }
    }
  }
}
