@import '../../../assets/_var.scss';
.introduction {
    padding-top: 10rem;
    padding-bottom: 6rem;
    background-image: url(../../../assets/images/common-shadow.png);
    background-position: -25rem -40rem;
    &_left {
        //    img {
        //     min-width: 7.5rem;
        //    }
        @media (min-width:1440px) {
            margin-left: -5.5rem;
            padding-bottom: 3rem;
        }
    }
    &_right {
        h2 {
            span {
                color: $yellowDark3;
                font-weight: 500;
                display: block;
                font-size: 1.6rem;
            }
            color: $white;
            margin-bottom: 1.5rem;
        }
        p {
            font-size: 1.4rem;
            font-weight: 500;
            margin-bottom: 3rem;
            &:last-of-type {
                margin-bottom: 5rem;
            }
        }
    }
    &_impact {
        overflow: hidden;
        padding-top: 7rem;
        width: 100%;
        &_left {
            h2 {
                color: $white;
                margin-bottom: 2.5rem;
            }
            p {
                font-size: 1.8rem;
                margin: 0;
            }
            &_list {
                &_inner {
                    margin-top: 2.8rem;
                    display: flex;
                    max-width: 51rem;
                    &_left {
                        flex: 0 0 8rem;
                        max-width: 8rem;
                    }
                    &_right {
                        flex: 0 0 calc(100% - 8rem);
                        max-width: calc(100% - 8rem);
                        h5 {
                            color: $white900;
                            font-size: 2rem;
                            margin-bottom: 1rem;
                            font-weight: normal;
                        }
                        p {
                            color: $gray98;
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
        &_right {
            position: relative;
            display: table;
            margin-left: auto;
            margin-right: -6.2rem;
            margin-top: -3rem;
            z-index: 1;
            &:before {
                content: "";
                height: 69.5rem;
                width: 69.5rem;
                background-image: url(../../../assets/images/grid.png);
                background-repeat: no-repeat;
                background-size: 68.9rem;
                background-position: center;
                position: absolute;
                right: -5rem;
                top: -3rem;
                z-index: -1;
            }
        }
        @media (max-width:1679px) {
            &_left {
                h2 {
                    margin-bottom: 1.5rem;
                }
                p {
                    font-size: 1.6rem;
                }
                &_list {
                    margin-top: 3rem;
                    &_inner {
                        margin-top: 2rem;
                        max-width: 100%;
                        &_left {
                            flex: 0 0 6rem;
                            max-width: 6rem;
                            svg {
                                width: 4rem;
                                height: 4rem;
                            }
                        }
                        &_right {
                            flex: 0 0 calc(100% - 6rem);
                            max-width: calc(100% - 6rem);
                            h5 {
                                font-size: 1.8rem;
                            }
                        }
                    }
                }
            }
            &_right {
                margin-right: 0;
                margin: 0 auto;
                padding-top: 5rem;
                >div {
                    max-width: 55rem !important;
                    width: 100% !important;
                }
            }
        }
        @media (max-width:1279px) {
            &_left {
                
                p {
                    font-size: 1.6rem;
                }
                &_list {
                    &_inner { 
                         
                        &_right { 
                            h5 {
                                font-size: 1.6rem;
                            }
                            p {font-size: 1.4rem;}
                        }
                    }
                }
            }
            
        }
    }
}