@import "../../../assets/_var.scss";

.dividend {
    :global {
        table {
            tbody {
                tr {
                    height: 8rem;
                }
            }
        }
    }
}

.claimed_btn {
    a {
        color: $green;

        svg {
            width: 1.8rem;
            height: 1.8rem;

            path {
                fill: $green;
            }
        }
    }
}