@import '../../../assets/_var.scss';

.profile_history {
    :global {
        table {
            tbody {
                tr {
                    height: 7rem;
                }
            }
        }
    }
}