@import '../../../../assets/_var.scss';

.profile_nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderClr2;
    &_icon {
        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
        @media (min-width:768px) {
            display: none;
        }
    }
    &_title {
        @media (max-width:767px) {
            display: none;
        }
    }
    li {
        flex: 0 0 14.2857142857%;
        max-width: 14.2857142857;
        
        a {
            padding: 1.5rem .5rem;
            color: $gray98;
            display: block;
            text-align: center;
            @extend %transition;
            position: relative;
            &:after {
                content: "";
                height: 2px;
                width: 100%;
                @extend %BgGradient;
                position: absolute;
                bottom: -1px;
                left: 0;
                opacity: 0;
            }
            &:hover  {
                color: $white;
            }
           
            
        }
        :global {
            a.active, a:hover{
                color: $white;
                &:after { 
                    opacity: 1;
                }
            }
            // a.active {
            //     span {
            //         &:last-of-type {
            //             display: block ;
            //             position: absolute;
            //         }
            //     }
            // }
        }
    }
    
}