@import "../../../assets/_var.scss";

.complete_kyc_modal {
    .modal-content {
        max-width: 49rem;
        margin: 0 auto;
    }

    &_lottie {
        display: table;
        margin: 0 auto;
    }

    p {
        font-size: 2.6rem;
        font-weight: 600;
    }
}