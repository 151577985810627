@import "../../../assets/_var.scss";

.no_result {
    margin: 0 auto;
    width: 72rem;
    text-align: center;

    @media(max-width:767px) {
        width: 100%;
    }
}