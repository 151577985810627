@import "../../../assets/_var.scss";

.header {
  position: relative;
  background-color: $themeClr27;
  // transition: .3s all ease-in-out;
  @extend %transition;
  height: auto;
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100%;
  padding: 1.65rem 0;

  @media (max-width: 1679px) {
    padding: 1rem 0;
  }

  @media (max-width: 1279px) {
    padding: 0.5rem 0;
  }

  // &_navbar {

  // }
  &_left {
    position: relative;
  }

  &_logo {
    img {
      @media (max-width: 1679px) {
        max-width: 7rem;
      }

      @media (max-width: 991px) {
        max-width: 6rem;
      }

      // @media (max-width:359px) {
      //     max-width: 12rem;
      // }
    }
    &:focus-visible {
      outline: none;
    }
  }

  &_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex: 1;
  }

  &_search {
    @media (min-width: 990px) {
      width: 100%;
      padding: 0 9rem;
    }

    @media (max-width: 991px) {
      display: none;
    }

    // button {
    // @media (min-width:1280px) {
    //     left: 2rem;
    //     top: 50%;
    //     position: absolute;
    //     transform: translateY(-50%);
    // }

    // @media (max-width:1279px) {
    //     position: static;
    //     height: 4rem;
    //     width: 4rem;
    //     border-radius: 50%;
    //     background-color: $themeClr;
    //     display: inline-flex;
    //     justify-content: center;
    //     align-items: center;
    // }
    // }
  }

  &_dropDown {
    @media (min-width: 1280px) {
      :global {
        div.dropdown-menu {
          // border: 2px solid #900;
          top: 100% !important;

          @media (min-width: 1280px) {
            transform: translateY(0) !important;
            display: block !important;
            transition: 0.3s all ease-in-out;
            pointer-events: none;
            top: calc(100% + 4rem) !important;
            visibility: hidden;
            opacity: 0;
          }

          &::before {
            content: none;
          }
        }
      }

      &:hover {
        :global {
          div.dropdown-menu {
            top: calc(100% - 1px) !important;
          }
        }
      }

      > div {
        display: block !important;
        transition: 0.3s all ease-in-out;
        // visibility: hidden;
        pointer-events: none;
        // opacity: 0;
        // transform: translateY(1rem);
      }

      &:hover {
        > div {
          visibility: visible;
          pointer-events: all;
          opacity: 1;
          // transform: translateY(0);
        }
      }
    }

    @media (max-width: 1279px) {
      :global {
        div.dropdown-menu {
          transform: translate(0px, 0) !important;
        }
      }
    }

    @media (max-width: 991px) {
      margin-left: 0;

      :global {
        a.dropdown-toggle {
          &:after {
            position: absolute;
            right: 2rem;
            top: 2rem;
          }
        }

        div.dropdown-menu {
          min-width: inherit;
          max-width: calc(100% - 4rem);
          width: 100%;
          margin-left: 2rem;
          transform: translate(0px, 0) !important;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  &_dropDown > a[class*="nav-link"],
  &_navlink,
  &_navlink[class*="dropdown-toggle"] {
    font-size: 1.6rem;
    color: $gray8E;
    padding: 2rem 0 !important;
    transition: 0.3s all ease-in-out;
    margin-right: 4.8rem;

    &::before {
      content: none !important;
    }

    &:hover,
    &:focus {
      color: $white !important;
    }

    @media (max-width: 1679px) {
      font-size: 1.4rem;
      margin-right: 3rem;
    }

    @media (max-width: 1439px) {
      padding: 2rem 0 !important;
      position: relative;
      margin-right: 2rem;
    }

    @media (max-width: 991px) {
      padding: 1.5rem 2rem !important;
    }

    &:last-child {
      margin-right: 0;
    }

    &[class*="active"] {
      color: $yellow !important;
    }
  }

  &_navlink,
  &_dropDown {
    @media (max-width: 991px) {
      border-bottom: 1px solid rgba($borderClr, 0.3);
    }
  }

  &_dropDown {
    &Item {
      &:first-child {
        margin-bottom: 0 !important;
      }

      &:last-child {
        margin-top: 0 !important;
      }

      @media (max-width: 1679px) {
        font-size: 1.4rem;
      }

      &:focus {
        background-color: transparent;
        color: $yellow;
      }

      &:hover {
        background-color: transparent;
        color: $yellow;
      }
    }
  }

  &_action {
    margin-left: 6rem;
    display: flex;
    align-items: center;

    :global {
      .badge.bg-primary {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        background: $redclr !important;
        position: absolute;
        z-index: 1;
        right: 0;
        top: -10px;
        font-weight: 400;
        line-height: 1.8rem;
      }
    }

    @media (max-width: 1679px) {
      margin-left: 4rem;
    }

    @media (max-width: 1439px) {
      margin-left: 2rem;
    }

    @media (max-width: 991px) {
      margin-left: 1.5rem;
    }

    @media (max-width: 767px) {
      margin-left: 1rem;
    }

    &_hasIcon {
      svg {
        margin-right: 8px;
      }
    }
  }

  &_userDropdown,
  &_notification_dropDown {
    @media (max-width: 991px) {
      padding: 1rem 0;
    }

    button {
      margin: 0;
      padding: 0;
      border: 0;
      border-radius: 0;

      &:focus,
      &:hover,
      &:active {
        outline: none;
        background-color: transparent !important;
        border-color: $borderClr !important;
        box-shadow: none !important;
      }

      &:after {
        content: none;
      }
    }
    > div {
      margin-top: 20px !important;
    }
  }

  &_wltBTn {
    white-space: nowrap;
    margin-left: 2rem;

    :global {
      span.icon {
        margin: 0;

        @media (min-width: 992px) {
          display: none;
        }

        svg {
          width: 1.8rem;
          height: 1.6rem;
        }
      }

      span {
        &:not(.icon) {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }
    }

    @media (max-width: 991px) {
      height: 4rem;
      width: 4rem;
      min-height: 4rem;
      min-width: 4rem;
      padding: 0;
      border-color: $themeClr !important;
      background-color: $themeClr;
      margin-left: 1.5rem;
    }
  }

  &_signIn,
  &_signUp {
    min-width: 12rem;
    white-space: nowrap;

    :global {
      span {
        &.icon {
          margin: 0;

          svg {
            width: 2.4rem;
            height: 2.4rem;

            path {
              fill: $white;
            }
          }

          @media (min-width: 992px) {
            display: none;
          }
        }

        &:not(.icon) {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }
    }

    @media (max-width: 991px) {
      height: 4rem;
      width: 4rem;
      min-height: 4rem;
      min-width: 4rem;
      padding: 0;
    }
  }

  &_signUp {
    margin-left: 2rem;

    @media (max-width: 991px) {
      margin-left: 1rem;
    }
  }

  &_notification_dropDown {
    > div[class*="dropdown-menu"] {
      min-width: 36rem;

      @media (min-width: 1280px) {
        transform: translate(-50%, 0) !important;
        left: 50% !important;
      }

      @media (max-width: 1279px) {
        transform: translate(0, 1rem) !important;
        left: auto;
        right: 0;
      }

      @media (max-width: 991px) {
        transform: translate(0, 0) !important;
      }

      @media (max-width: 767px) {
        // transform: translate(0, 0) !important;
        left: auto !important;
        right: -8.9rem;
      }
    }

    button[class*="dropdown-toggle"] {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      position: relative;
      background-color: $themeClr !important;
      display: inline-flex;
      justify-content: center !important;
      align-items: center !important;
      padding: 0 !important;
      @extend %transition;

      &::after,
      &::before {
        content: none !important;
      }

      @media (max-width: 1679px) {
        height: 4rem;
        width: 4rem;

        svg {
          width: 1.5rem;
        }

        // &::after {
        //     content: "";
        //     height: .7rem;
        //     width: .7rem;
        // }
      }
    }

    &:hover {
      @media (min-width: 1280px) {
        > div[class*="dropdown-menu"] {
          transform: translate(-50%, 0) !important;
          // @media (min-width:1279px) {
          // }
        }

        :global {
          button[class*="dropdown-toggle"] {
            background: transparent
              linear-gradient(180deg, $yellow 0%, $yellowDark 100%) 0% 0%
              no-repeat padding-box;

            svg {
              path {
                fill: $white900;
              }
            }
          }
        }
      }

      @media (max-width: 767px) {
        > div[class*="dropdown-menu"] {
          transform: translate(0, 0) !important;
        }

        // left: auto !important;
        // right: 0;
      }
    }

    @media (max-width: 1679px) {
      > div[class*="dropdown-menu"] {
        // right: 100% !important;
        min-width: 30rem;
      }
    }

    @media (max-width: 1279px) {
      > div[class*="dropdown-menu"] {
        min-width: 27rem;
      }

      // :global {
      &[class*="show"] {
        button[class*="dropdown-toggle"] {
          background: transparent
            linear-gradient(180deg, $yellow 0%, $yellowDark 100%) 0% 0%
            no-repeat padding-box;

          svg {
            path {
              fill: $white900;
            }
          }
        }
      }

      // }
    }
  }

  &_userDropdown {
    margin-left: 2rem;

    button[class*="dropdown-toggle"] {
      white-space: nowrap;
      width: auto;
      min-width: 22.6rem;
      background-color: transparent;
      position: relative;
      display: inline-flex;
      align-items: center;
      font-size: 1.6rem;
      border: 1px solid $borderClr;
      border-radius: 3rem;
      padding: 0.6rem;
      padding-right: 1.6rem;
      font-weight: 600;
      color: $white;

      img {
        height: 3.6rem;
        width: 3.6rem;
        min-height: 3.6rem;
        min-width: 3.6rem;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 0.5rem;
      }

      span {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding-left: 5px;
      }

      &:after {
        margin-left: 2rem;
        border-left-color: $white;
      }

      @media (max-width: 1679px) {
        font-size: 1.4rem;

        padding: 0.7rem 0.6rem;

        img {
          height: 2.5rem;
          width: 2.5rem;
          min-height: 2.5rem;
          min-width: 2.5rem;
        }
      }

      @media (max-width: 1439px) {
        font-size: 1.2rem;
      }

      @media (max-width: 767px) {
        min-width: inherit;
        padding: 0.7rem;
        height: 4rem;
        width: 4rem;

        &:after {
          content: none;
        }

        span {
          display: none;
        }
      }
    }

    > div {
      // margin-top: 20px !important;
      min-width: 22.1rem;
      left: auto !important;
      right: 0;

      @media (max-width: 991px) {
        transform: translate(0px, 0) !important;
      }
    }

    &:hover {
      > div[class*="dropdown-menu"] {
        @media (min-width: 1280px) {
          transform: translate(0, 0) !important;
        }
      }
    }

    a {
      border-bottom: 0;
      color: $gray98;

      &:hover,
      &:focus {
        color: $yellow;
        background-color: transparent;
      }
    }

    &Head {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      padding-top: 15px;

      &Thumb {
        margin-right: 5px;
        flex: 0 0 3.6rem;

        img,
        video {
          height: 3.6rem;
          width: 3.6rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      &Content {
        p {
          font-size: 1.2rem;
          color: $gray98;
          margin: 0;
          span {
            cursor: pointer;
          }
        }

        h4 {
          font-size: 2rem;
          font-weight: bold;
          color: $white;
          margin: 0;
        }
      }
    }

    &AddFund {
      padding: 0 2rem;
      margin: 1rem 0 0.5rem 0;

      button {
        font-size: 1.2rem;
        padding: 0.7rem 1rem;
        border-radius: 3rem;
        width: 100%;
        font-weight: 600;
      }
    }

    @media (max-width: 1679px) {
      margin-left: 1rem;

      &Head {
        &Content {
          h4 {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  &_toggleBtn {
    position: relative;
    padding: 0;
    margin-left: 1.3rem;
    width: 2rem;

    @media (max-width: 767px) {
      margin-left: 1rem;
    }

    span {
      display: block;
      width: 2.5rem;
      height: 2px;
      margin: 0.6rem 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      background-color: $yellowDark;
      transition: 500ms;
      transform-origin: right center;
      transition: 0.3s all ease-in-out;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 0;
    }
  }

  @media (max-width: 991px) {
    &_navbarCollapse {
      background-image: url(../../../assets/images/logo.png);
      background-repeat: no-repeat;
      background-position: center 1.6rem;
      background-size: 6rem;
      padding-top: 8.5rem;
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh !important;
      min-height: 100vh !important;
      overflow-y: auto;
      width: 25rem;
      background-color: $themeClr;
      display: block !important;
      transform: translateX(-25rem);
      transition: 0.3s all ease-in-out;
      z-index: 999;

      > div {
        &:first-child {
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          // padding-top: 2rem;
        }
      }
    }
  }

  // @media (max-width:359px) {
  //     &_navbarCollapse {
  //         background-size: 12rem;
  //         background-position: center 2.2rem;
  //     }
  // }

  // &_toggleBtn  {
  :global {
    @media (max-width: 991px) {
      .navbar-collapse {
        &.show {
          transform: translateX(0);
          box-shadow: 0 0 24px rgba(0, 0, 0, 90%);
        }
      }
    }

    .navbar-toggler {
      &:not(.collapsed) {
        span {
          width: 2.35rem;

          &:first-child {
            transform: rotate(-45deg);
          }

          &:nth-child(2) {
            transform: scale(0);
          }

          &:last-child {
            transform: rotate(45deg);
          }
        }
      }
    }
    .navbar-collapse {
      flex-grow: initial;
    }
  }

  // }
  &[class*="tiny"] {
    // padding: .8rem 0;
    background-color: rgba($themeClr2F, 0.9);
    box-shadow: 1rem 0 1.4rem rgba($yellow, 0.1);
    padding: 1rem 0;

    @media (max-width: 1679px) {
      padding: 0.5rem 0;
    }

    // @media (max-width:1279px) {
    //     padding: .5rem 0;
    // }
  }
}

.headerBackdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: $black, $alpha: 0.4);
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(11px);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }
  .hasIcon {
    border: 2px solid #900;
  }
}
