@import '../../assets/_var.scss';
.error {
    position: relative;
    padding: 10rem 0 10rem;
    z-index: 1;
    text-align: center;

    h1 {
        color: $white;

        span {
            @extend %commonTextGradient;
        }
    }

    p {
        color: $gray98;
        font-size: 2rem;
        font-weight: 500;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    &_left {
 
        a {
            min-width: 20.8rem;
            margin-right: 2rem;
            margin-top: 1rem;
        } 
        &_action {
            display: flex;
            // align-items: center;
            justify-content: center;
            a {
                margin: 1rem;
            }
        }
        @media (max-width:767px) {
            // max-width: 50rem;
             padding-right: 0;
            text-align: center;
            &_action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                a {
                    min-width: inherit;
                    flex: 0 0 49%;
                    max-width: 49%;
                    margin: .5rem 0 0 0;
                }
            }
        }
    }
 

    @media (max-width:1679px) {
        padding: 7rem 0 7rem;
        p {
            font-size: 1.8rem;
        }
    }

    @media (max-width:1439px) {
        padding: 5rem 0 5rem;
        p {
            font-size: 1.6rem;
        }
    }
}