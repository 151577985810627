@import '../../../../assets/_var.scss';
.howitworks_card {
    background-color: $themeClr2F;
    padding: 2.4rem 3.6rem 4.3rem 2.4rem;
    border-radius: 3rem;
    height: 100%;
    overflow: hidden;
    @extend %transition;
    &_thumb {
        height: 5.8rem;
        width: 5.8rem;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($yellow, .1);
        margin-bottom: 2.5rem;
        box-shadow: 0px 3px 38px rgba($yellowD5, .1);
    }
    h5 {
        color: $white900;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    p {
        color: $gray98;
        font-size: 1.2rem;
        line-height: 2;
    }
    &:hover {
        background-color: $gray3D;
    }
}