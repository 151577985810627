@import "../../../assets/_var.scss";

.hero {
  position: relative;
  padding: 10rem 0 10rem;
  z-index: 1;

  h1 {
    color: $white;

    span {
      @extend %commonTextGradient;
    }
  }

  p {
    color: $gray98;
    font-size: 2rem;
    font-weight: 500;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  &_left {
    max-width: 60.3rem;

    button {
      min-width: 20.8rem;
      margin-right: 2rem;
      margin-top: 1rem;
    }

    @media (max-width: 991px) {
      // max-width: 50rem;
      padding-right: 4rem;
    }
    @media (max-width: 767px) {
      // max-width: 50rem;
      max-width: 100%;
      padding-right: 0;
      text-align: center;
      &_action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          min-width: inherit;
          flex: 0 0 49%;
          max-width: 49%;
          margin: 0.5rem 0 0 0;
        }
      }
    }
  }

  &_right {
    &_inner {
      @media (min-width: 1680px) {
        padding-right: 4rem;
      }

      &_vdo {
        height: 60rem;
        width: 100%;
        max-width: 53.5rem;
        border-radius: 5.4rem;
        position: relative;
        z-index: 1;
        margin: 0 auto;
        margin-top: 5rem;

        @media (min-width: 768px) {
          margin-left: auto;
          margin-right: 0;
          margin-top: 0;
        }

        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 5.4rem;
          background-color: $gray32;
        }

        &::before,
        &::after {
          content: "";
          border-radius: 5.4rem;
          height: calc(100% - 3rem);
          width: calc(100% - 5rem);
          position: absolute;
          z-index: -1;
          pointer-events: none;
          left: -1rem;
          top: 4rem;
        }

        &::before {
          z-index: -1;
          background-color: $gray32;
          transform: rotate(-8deg);
          transition: all 0.5s ease-in-out;
        }

        &::after {
          z-index: -2;
          background-color: $gray2D;
          transform: rotate(-16deg);
          top: 8rem;
          height: calc(100% - 5rem);
          transition: all 0.5s ease-in-out;
        }
        &:hover {
          &::before {
            transform: rotate(-0deg);
            top: 0rem;
            left: 0rem;
            transition: all 0.5s ease-in-out;
          }
          &::after {
            transform: rotate(-0deg);
            top: 0rem;
            left: 0rem;
            transition: all 0.5s ease-in-out;
          }
        }
      }

      &_label {
        background-color: rgba($white, 0.1);
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2rem;
        width: 100%;
        border-bottom-left-radius: 5.4rem;
        border-bottom-right-radius: 5.4rem;
        justify-content: center;
        backdrop-filter: blur(5rem);
        -webkit-backdrop-filter: blur(5rem);

        img {
          flex: 0 0 4.2rem;
          max-width: 4.2rem;
        }

        h4 {
          font-size: 1.6rem;
          font-weight: bold;
          color: $white;
          margin: 0;
          padding-left: 1.5rem;
          letter-spacing: 0.36rem;
        }
      }

      @media (max-width: 1679px) {
        &_vdo {
          height: 55rem;
          max-width: 53.5rem;
        }

        &_label {
          padding: 1.5rem;
          border-bottom-left-radius: 3.8rem;
          border-bottom-right-radius: 3.8rem;

          h4 {
            font-size: 1.4rem;
          }
        }
      }

      @media (max-width: 1439px) {
        &_vdo {
          height: 45rem;
          max-width: 45rem;
        }

        &_label {
          padding: 1.5rem;
          border-bottom-left-radius: 3.6rem;
          border-bottom-right-radius: 3.6rem;

          h4 {
            font-size: 1.2rem;
          }
        }
      }
      @media (max-width: 991px) {
        &_vdo {
          height: 35rem;
        }
      }
    }
  }

  @media (max-width: 1679px) {
    padding: 7rem 0 7rem;
    p {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 1439px) {
    padding: 5rem 0 5rem;
    p {
      font-size: 1.6rem;
    }
  }
}

:global {
  .home-controller {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(../../../assets/images/common-shadow.png),
      url(../../../assets/images/common-shadow.png),
      url(../../../assets/images/common-shadow.png),
      url(../../../assets/images/common-shadow.png),
      url(../../../assets/images/hero-waves.png);
    background-size: 150rem, 150rem, 150rem, 150rem, 42%;
    background-position: -70rem 4rem, calc(100% + 70rem) 90rem, -75rem 150rem,
      calc(100% + 70rem) calc(100% - 100rem), right 10rem;
    @media (min-width: 1920px) {
      background-size: 150rem, 150rem, 150rem, 150rem, 80rem;
    }
  }
  .commonBtn.exploreBtn {
    margin-right: 1.4rem;
    width: 175px;
  }
}
