@import "../../../assets/_var.scss";

.common_table {
  .table {
    margin-bottom: 0;
  }

  thead {
    th {
      color: $gray98;
      font-size: 1.6rem;
      font-weight: normal;
      padding: 0 1rem;
      white-space: nowrap;
      vertical-align: middle;
      border: 0px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @media (max-width: 1439px) {
        font-size: 1.4rem;
        padding: 1.6rem 1rem;
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $borderClr2;
        white-space: nowrap;
        padding: 1.5rem 1rem;
        vertical-align: middle;
        font-size: 1.8rem;
        color: $white900;
        font-weight: 500;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        @media (max-width: 1439px) {
          font-size: 1.4rem;
          padding: 2rem 1rem;
        }

        @media (max-width: 1439px) {
          padding: 1rem;
        }
      }

      &:first-child {
        td {
          padding-top: 0;
        }
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  &.table-box {
    padding: 3rem;
    border-radius: 2.6rem;
    background-color: $themeClr2F;

    @media (max-width: 1279px) {
      padding: 2rem;
    }
  }
}
