@import "../../../assets/_var.scss";

.market_head {
  ul {
    margin: 1rem 0 3rem;
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      margin: 2rem 0;
    }

    @media (max-width: 575px) {
      margin: 0;
    }

    @media (max-width: 1279px) {
      font-size: 3rem;
    }

    li {
      a {
        color: $gray100;
        margin: 0 3.6rem 0 0;

        @media (max-width: 991px) {
          margin: 0 2rem 0 0;
        }
      }

      :global {
        a.active {
          color: $white;
          font-weight: 700;
        }
      }
    }
  }

  :global {
    .hl {
      background-color: $lightgray;
      width: 100%;
      height: 1px;
      margin: 3rem 0;

      @media (max-width: 767px) {
        margin: 1rem 0 2rem;
      }
    }
  }

  &_search {
    width: 100%;
    position: relative;

    input[class*="form-control"] {
      border: 1px solid $borderClr3 !important;
    }

    @media (max-width: 991px) {
      margin: 0rem 0 1rem;
    }
  }

  .activity_sec_act {
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;

      > * {
        width: 48%;

        > div,
        > div > button,
        > div > div {
          width: 100%;
        }
      }
    }
    @media (max-width: 458px) {
      flex-direction: column;
    }
    :global {
      .me-3.select-category-btn {
        button {
          min-width: 200px;
        }
        @media (max-width: 458px) {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
