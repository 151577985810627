@import "../../../assets/_var.scss";

.signup_modal {
    :global {
        .modal-dialog {
            .modal-header {
                .modal-title {
                    width: 100%;

                  

                    
                    @media (max-width:1279px) {
                        img {
                          max-width: 6rem;
                          width: 100%;
                        }
                      }
                }
            }

            .modal-body {
                @media(max-width: 1279px) {
                    font-size: 1.4rem;
                }

                .form-control {
                    background-color: #3D3C46;
                }
                .form-label {
                    font-size: 1.6rem;
                    color: $white900;
                }

                p.commonModal-subtitle {
                    font-size: 3rem;
                    font-weight: 600;
                    margin-top: 3rem;
                    margin-bottom: 4rem;
                    line-height: 1.8rem;

                    @media(max-width:1279px) {
                        font-size: 2.6rem;
                        margin-bottom: 2.8rem;
                    }

                    @media(max-width:575px) {
                        font-size: 2.2rem;
                        margin-bottom: 2rem;
                    }
                }

                p {
                    font-size: 1.4rem;
                }

                .form-check {
                    display: flex;
                    align-items: center;


                    .form-check-input[type=checkbox] {
                        margin-top: 2rem;
                        height: 1.8rem;
                        width: 1.8rem;
                        background-color: transparent;
                        box-shadow: none;
                        padding: 0;
                        flex-shrink: 0;
                        border-radius: 50% !important;

                        @media(max-width: 767px) {
                            margin-top: 0;
                        }
                    }

                    .form-check-input[type=checkbox]:checked[type=checkbox] {
                        background-color: $yellowD7;
                        border-color: $yellowD7;
                    }

                    .confirm_sec {
                        margin-top: 2rem;
                        font-weight: 500;

                        @media(max-width: 767px) {
                            margin-top: 0;
                        }

                        p {
                            font-size: 1.6rem;
                            color: $gray98;
                            margin-bottom: 0;

                            @media(max-width: 767px) {
                                font-size: 1.4rem;
                            }

                            @media(max-width: 479px) {
                                font-size: 1.2rem;
                            }

                            a {
                                color: $yellowD7;
                                @extend %transition;
                                &:hover {
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                .signup_note {
                    margin-top: 2rem;
                    text-align: center;

                    p {
                        font-size: 1.6rem;
                        color: $gray98;
                        font-weight: 500;

                        @media(max-width: 767px) {
                            font-size: 1.4rem;
                        }
                        @media(max-width: 479px) {
                            font-size: 1.2rem;
                        }
                        a {
                            color: $yellowD7;
                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }


}