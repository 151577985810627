@import '../../../assets/_var.scss';

.banner {
    overflow: hidden;
    padding-bottom: 10rem;

    // justify-content: space-between;
    &_inner {
        display: flex;
        align-items: center;
        position: relative;
    }

    &_left {
        @media (min-width:992px) {
            position: absolute;
            left: 0;
            transform: translateY(-50%);
            top: 50%;
            z-index: 1;
        }

        flex: 0 0 67.4rem;
        max-width: 67.4rem;
        backdrop-filter: blur(32px);
        background-color: rgba($themeClr, 70%);
        padding: 7.5rem 6rem 7.8rem 0;

        h1 {
            color: $white900;
            margin-bottom: 0;

            span {
                @extend %commonTextGradient;
            }

        }

        p {
            font-size: 2rem;
            color: $gray98;
            margin-top: 2rem;
            margin-bottom: 4rem;
            line-height: 1.8;
            font-weight: 500;

        }

        ul {
            display: flex;
            align-items: flex-start;
            border-top: 1px solid $lightgray;

            li {
                padding-right: 8rem;
                padding-top: 4rem;

                span {
                    font-size: 1.8rem;
                    color: $gray98;
                    font-weight: 500;
                    display: block;
                }

                h3 {
                    font-size: 4rem;
                    color: $white;
                    margin: 0;
                }
            }
        }


    }

    &_right {
        margin-left: auto;
        max-width: 69.6%;
        flex: 0 0 69.6%;

        &_vdo {
            width: 100%;

            video {
                width: 100%;
                height: 77.4rem;
                object-fit: cover;
            }
        }
    }

    @media (max-width:1679px) {
        &_left {
            flex: 0 0 59rem;
            max-width: 59rem;

            p {
                font-size: 1.8rem
            }

            ul {
                li {
                    span {
                        font-size: 1.6rem;
                    }

                    h3 {
                        font-size: 3.6rem;
                    }
                }
            }

        }

        &_right {

            // max-width: 65%;
            &_vdo {
                video {
                    height: 66rem;
                }
            }
        }
    }

    @media (max-width:1439px) {
        &_left {
            flex: 0 0 52rem;
            max-width: 52rem;
            padding: 5rem 5rem 5rem 0;

            p {
                font-size: 1.6rem
            }

            ul {
                li {
                    span {
                        font-size: 1.4rem;
                    }

                    h3 {
                        font-size: 3rem;
                    }
                }
            }

        }

        &_right {

            // max-width: 65%;
            &_vdo {
                video {
                    height: 60rem;
                }
            }
        }
    }

    @media (max-width:991px) {
        padding-bottom: 5rem;
        &_inner {

            flex-wrap: wrap;
        }

        &_left {

            flex: 0 0 80%;
            max-width: 80%;
            padding: 5rem;
            margin: 0 auto;
            margin-top: -7rem;
            padding-bottom: 0;

            ul {
                li {

                    h3 {
                        font-size: 2.6rem;
                    }
                }
            }

        }

        &_right {
            flex: 0 0 100%;
            max-width: 100%;

            &_vdo {
                video {
                    height: 50rem;
                }
            }
        }
    }

    @media (max-width:767px) {

        &_left {

            flex: 0 0 90%;
            max-width: 90%;
            padding: 3rem;
            text-align: center;
            padding-bottom: 0;

            ul {
                justify-content: space-between;

                li {
                    padding: 3rem 1.5rem 0;

                }
            }


        }

        &_right {
            &_vdo {
                video {
                    height: 40rem;
                }
            }
        }
    }

    @media (max-width:575px) {

        &_left {

            flex: 0 0 90%;
            max-width: 90%;
            padding: 3rem 2rem 0;

            ul {
                flex-wrap: wrap;

                li {
                    padding-top: 2rem;
                    flex: 0 0 100%;
                    max-width: 100%;

                }
            }


        }

        &_right {
            &_vdo {
                video {
                    height: 30rem;
                }
            }
        }
    }
}