@import "../../../assets/_var.scss";

.common_dropdown {
  display: inline-flex;

  .dropdown-toggle {
    background-color: $themeClr2F;
    border: 0;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 1rem 1.5rem 1rem 1rem !important;
    border-radius: 3rem;
    display: inline-flex;
    align-items: center;

    &:active {
      background-color: $themeClr2F;
    }

    padding-right: 3.3rem !important;

    &:after {
      position: absolute;
      right: 1.5rem;
      // transform: translateY(-50%);
      // top: 50%;
    }
  }

  &.dark {
    .dropdown-toggle {
      background-color: $themeClr;
    }
  }

  &_icon {
    height: 3.3rem;
    width: 3.3rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    flex-shrink: 0;

    img,
    svg {
      height: 3.3rem;
      width: 3.3rem;
      border-radius: 50%;
    }
  }

  .dropdown-item {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 2px solid #900;

    .checkicon {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.selectbox {
    .dropdown-item {
      padding-right: 4rem;
    }
  }
}
