@import "../../assets/_var.scss";

.activity_sec {
    margin: 4rem 0 10rem;

    @media(max-width: 991px) {
        margin: 0rem 0;
    }

    &_head {

        .hl {
            width: 100%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.1);
            margin: 3rem 0;

            @media(max-width: 767px) {
                margin: 2rem 0;
            }
        }
    }

    &_table {
        :global {
            table {
                thead {
                    tr {
                        height: 5rem;
                    }
                }

                tbody {
                    tr {
                        height: 7rem;
                    }
                }
            }
        }
    }



    &_search {
        position: relative;

        input[class*="form-control"] {
            border: 1px solid $borderClr3 !important;
        }

        @media(max-width: 991px) {
            margin: 0rem 0 1rem;
        }
    }

    &_act {
        @media (max-width:767px) {
            display: flex;
            justify-content: space-between;

            >* {
                width: 48%;

                >div,
                >div>button,
                >div>div {
                    width: 100%;
                }
            }
        }
    }
}