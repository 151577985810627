@import "../../assets/_var.scss";
.profile_sec {
  &_head {
    h2 {
      margin-top: 4rem;
      color: $white;
      margin-bottom: 2rem;
    }
    p {
      color: $gray98;
      font-weight: 500;
      margin-bottom: 2rem;
    }
    .hl {
      background-color: #3e3e3e;
      height: 1px;
      width: 100%;
      margin: 2rem 0 3rem;
    }
  }
  h5 {
    color: $white;
    margin-bottom: 0.7rem;
    font-weight: 600;
  }
  &_left {
    p {
      font-size: 1.3rem;
      margin-top: 0.7rem;
      padding-right: 10rem;
      line-height: 2.2rem;
      @media (max-width: 479px) {
        padding-right: unset;
      }
    }
    :global {
      textarea {
        height: 14.95rem !important;
        @media (max-width: 767px) {
          .row {
            // background-color: red;
            margin: 0 -1rem;
            > div {
              padding: 0 1rem;
            }
          }
          textarea {
            height: 3.3rem !important;
          }
        }
      }
    }
    .profile_img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22.5rem;
      height: 22.5rem;
      border-radius: 50%;
      background-size: cover;
      object-fit: cover;
      background-position: center;
      cursor: pointer;
      z-index: 1;
      transition: 0.2s ease-in;
      background-color: $gray3D;
      margin-bottom: 6rem;
      overflow: hidden;

      @media (max-width: 479px) {
        margin: 2rem auto;
      }
      &_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 50%;
        width: 100%;
        height: 100%;

        button {
          position: absolute;
        }
        @extend %transition;
        svg {
          opacity: 0;
          @extend %transition;
        }
        input {
          position: absolute;
          content: "";
          width: 22.5rem;
          height: 22.5rem !important;
          border-radius: 50% !important;
          opacity: 0;
          top: 0;
        }
      }
      &:hover {
        .profile_img {
          &_icon {
            background-color: rgba($black, 0.4);
            svg {
              opacity: 1;
            }
          }
        }
      }
      &.noUserImage {
        background: url(../../assets/images/profile-img.png);
      }
    }

    img {
      width: 225px;
      min-width: 225px;
      height: 225px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
    &_phoneInput {
      &_phoneInputStyle {
        height: 5rem;
        border-radius: 3rem;
        background-color: #3d3c46;
        padding: 1.6rem 2rem;

        input {
          background: transparent;
          border: 0px;
          color: $white;
          outline: none;
        }
        select {
          option {
            font-size: 14px;
          }
        }
      }
      img {
        width: 100%;
        min-width: auto;
        height: 100%;
        border-radius: 0;
      }
    }
  }
  &_right {
    p {
      font-size: 1.3rem;
      line-height: 2.2rem;
    }
    .profile_banner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      border-radius: 2.8rem;
      height: 22.5rem;
      background-size: cover;
      margin-bottom: 5rem;
      margin-top: 2rem;
      transition: 0.2s ease-in;
      background-color: $gray3D;
      overflow: hidden;

      cursor: pointer;
      @media (max-width: 991px) {
        border-radius: 4rem;
      }
      @media (max-width: 479px) {
        margin: 2rem auto;
      }
      &_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 2.8rem;
        width: 100%;
        height: 100%;

        @extend %transition;
        svg {
          opacity: 0;
          @extend %transition;
        }
        input {
          position: absolute;
          content: "";
          width: 100%;
          height: 22.5rem !important;
          border-radius: 2.8rem !important;
          opacity: 0;
          top: 0;
        }
      }
      &:hover {
        .profile_banner {
          &_icon {
            background-color: rgba($black, 0.4);
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
    &_emailEdit {
      color: #fff;
      position: absolute;
      right: 1px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 0 15px 15px 0;
      padding: 10px 22px 10px 22px;
      color: $yellowD7;
      text-decoration: underline;
      &::before {
        position: absolute;
        content: "";
        width: 1px;
        height: 37px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: $borderClr;
        font-weight: 600;
        font-size: 1.6rem;
      }
    }
  }
  :global {
    label {
      margin-top: 1rem;
      color: $white900;
      font-size: 1.6rem;
      font-weight: 400;
    }
    .commonBtn {
      max-width: 43rem;
      width: 100%;
      margin-top: 5rem;
      @media (max-width: 479px) {
        max-width: 100%;
        span {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.changeEmail {
  &_inputStyle {
    margin-top: 53px;
    margin-bottom: 25px;
  }
  :global {
    .form-label {
      color: $white900;
    }
  }
  &_noteStyle {
    span {
      color: $yellowD7;
      font-size: 1.4rem;
      display: block;
    }
    p {
      color: $white;
      font-size: 1.4rem;
      line-height: 2.4rem;
      display: block;
      margin-bottom: 4rem;
    }
  }
  &_changePassword {
    margin-top: 2.4rem;
    width: 100%;
  }
}
