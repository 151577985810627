@import "../../../assets/_var.scss";

.collection_category {
  margin-top: 6rem;
  padding: 3rem 0 7rem;

  @media (max-width: 991px) {
    margin-top: 3rem;
  }

  @media (max-width: 767px) {
    justify-content: center;
    width: 100%;
    // padding: 2rem;
    margin-top: 0rem;
  }

  &_inner {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    align-items: center;
    background-color: $themeClr2F;
    border-radius: 3rem;
    padding: 1.5rem;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    @media (max-width: 767px) {
      justify-content: center;
      width: 100%;
    }

    h4 {
      font-size: 3rem;
      color: $white;

      @media (max-width: 991px) {
        font-size: 2.5rem;
      }
    }

    &_data {
      flex: 0 0 50%;
      padding: 0 2rem 0 2.5rem;

      @media (max-width: 991px) {
        flex: 0 0 100%;
        padding: 0;
        width: 100%;
      }

      &_desc {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        > div {
          margin-right: 6.4rem;

          h3 {
            color: $white;
          }
        }
      }

      a {
        text-decoration: underline;
        color: $yellowD7;
        font-size: 1.4rem;
      }

      h4 {
        line-height: 9.3rem;

        @media (max-width: 991px) {
          line-height: 5rem;
        }
      }

      p {
        line-height: 2.3rem;
        font-size: 1.4rem;
      }

      &_desc {
        p {
          margin: 0;
        }
      }
    }

    &_img {
      flex: 0 0 50%;

      img {
        width: 100%;
        height: 304px;
        border-radius: 25px;
        object-fit: cover;
        background-position: center;
      }

      @media (max-width: 991px) {
        flex: 0 0 100%;
        margin-top: 2rem;
        width: 100%;
      }
    }
  }
}

.category_search {
  position: relative;

  input[class*="form-control"] {
    border: 1px solid $borderClr3 !important;
  }

  @media (max-width: 991px) {
    margin: 0rem 0 1rem;
  }
}

.collect_card {
  margin: 1rem 0;
}
