@import '../../../../assets/_var.scss';

.search_filter {
    // position: relative;
    padding-top: 3rem;

    &_search {
        position: relative;
      input[class*="form-control"] {
        border: 1px solid $borderClr3 !important;
      }
    }
    &_action {
      display: flex;
      justify-content: flex-end;
      > div {
        margin-left: 2rem;
        
      }
      @media (max-width:767px) {
        justify-content: space-between;
      > div {
        width: 47%;
        margin: 0;
        :global {
          .dropdown-toggle, .dropdown, .dropdown-menu {
            width: 100%;
          }
          
        }
      }
      }
    }
}