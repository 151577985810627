@import "../../../assets/_var.scss";

.ticket_modal {
  .modal-title {
    font-size: 3rem !important;
    margin-bottom: 5.3rem !important;

    @media (max-width: 1439px) {
      font-size: 2.4rem !important;
      margin-bottom: 3.3rem !important;
    }
  }

  .modal-body {
    .form-label {
      color: $white900 !important;
      font-size: 1.6rem;
    }
  }

  textarea {
    height: 14.95rem !important;

    @media (max-width: 767px) {
      .row {
        margin: 0 -1rem;

        > div {
          padding: 0 1rem;
        }
      }

      textarea {
        height: auto;
      }
    }
  }

  .btn_sec {
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;

    .commonBtn {
      flex: 0 0 47%;
    }
  }
}

.descTextareaStyle {
  overflow: auto !important;

  resize: none;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray3D;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $gray3D;
  }
}
