@import '../../../assets/_var.scss';
.Footer {
    position: relative;
    background-color: $themeClr27;
    padding: 6rem 0 0 0;
    h4 {
        color: $white;
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 2.4rem;
        margin-top: 2rem;
        @media (max-width:1439px) {
            margin-bottom: 1.4rem;
            font-size: 1.6rem;
        }
    }
    &About {
        p {
            color: $white900;
            margin-top: 2.4rem;
            margin-bottom: 3.5rem;
            font-size: 1.6rem;
            max-width: 37rem;
            line-height: 1.7;
        }
        @media (max-width:767px) {
            text-align: center;
            img {
                max-width: 8rem;
                width: 100%;
            }
            p {
                max-width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
    &Social {
        display: flex;
        li {
            margin: .5rem .4rem 0 0;
            a {
                @extend %transition;
                display: block;
            }
            &:hover {
                a {
                    transform: rotateY(360deg);
                }
            }
        }
        @media (max-width:767px) {
            justify-content: center;
            margin-bottom: 2.5rem;
            li {
                margin: .5rem;
            }
        }
    }
    &Quick {
        li {
            display: block;
            margin-bottom: 1rem;
            a {
                color: $gray98;
                font-size: 1.4rem;
                @extend %transition;
                &:hover {
                    color: $white900;
                }
            }
        }
    }
    &StayTouch {
        p {
            font-size: 1.4rem;
            max-width: 29rem;
        }
        form {
            margin-top: 4.5rem;
            position: relative;
            input {
                padding-right: 16rem !important;
                font-size: 1.2rem !important;
                &::placeholder {
                    color: $gray80;
                }
                &:-moz-placeholder {
                    color: $gray80;
                }
                &::-webkit-input-placeholder {
                    color: $gray80;
                }
            }
            button {
                position: absolute;
                right: 0;
                top: 0;
                min-width: 14.6rem;
                font-weight: 500;
                height: 100%;
            }
            @media (max-width:991px) {
                input {
                    padding-right: 12rem !important;
                }
                button {
                    min-width: 10rem;
                }
            }
        }
    }
    &CopyRight {
        margin-top: 5rem;
        border-top: 1px solid rgba($black, .41);
        padding: 4rem 0;
        p {
            margin: 0;
            color: $white900;
            font-size: 1.4rem;
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            li {
                margin-left: 2rem;
                a {
                    color: $white900;
                    font-size: 1.4rem;
                    @extend %transition;
                    &:hover {
                        color: $gray98;
                    }
                }
            }
        }
        @media (max-width:767px) {
            padding: 2rem 0;
            p {
                text-align: center;
            }
            ul {
                justify-content: center;
                margin: 1rem;
                li {
                    margin: 1rem 1rem 0;
                }
            }
        }
    }
    @media (max-width:767px) {
        padding: 4rem 0 0 0;
    }
}