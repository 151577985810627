// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import "./assets/_var.scss";
// @import "react-loading-skeleton/dist/skeleton.css";

html,
body,
#root,
.App {
  display: flex;
  flex-direction: column;
}

#root,
body,
.App {
  flex: 1 auto;
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

html {
  font-size: 62.5%;
  min-height: 100%;

  body {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    font-family: $baseFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $gray98;
    background-color: $themeClr;
    overflow-x: hidden;
    padding-top: 11rem;

    @media (max-width: 1679px) {
      padding-top: 9.125rem;
    }

    @media (max-width: 1279px) {
      padding-top: 8.125rem;
    }
  }
}

.App {
  justify-content: space-between;

  main {
    margin-bottom: auto;
  }
}

a {
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;

  &:focus,
  &:focus-within,
  &:focus-visible,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

img,
svg {
  max-width: 100%;
}

.container {
  max-width: 1396px;
  padding: 0 15px;

  &-fluid {
    padding: 0 15px;
  }

  @media (max-width: 1679px) {
    max-width: 1300px;
  }

  @media (max-width: 1439px) {
    max-width: 1200px;
  }
}

.row {
  margin: 0 -15px;

  > div {
    padding: 0 15px;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: 6.5rem;

  @media (max-width: 1679px) {
    font-size: 5.4rem;
  }

  @media (max-width: 1439px) {
    font-size: 4.8rem;
  }

  @media (max-width: 1279px) {
    font-size: 4.4rem;
  }

  @media (max-width: 991px) {
    font-size: 4rem;
  }

  @media (max-width: 767px) {
    font-size: 3.6rem;
  }
}

h2,
.h2 {
  font-size: 4.5rem;

  @media (max-width: 1679px) {
    font-size: 4rem;
  }

  @media (max-width: 1439px) {
    font-size: 3.4rem;
  }

  @media (max-width: 1279px) {
    font-size: 3rem;
  }

  @media (max-width: 991px) {
    font-size: 2.8rem;
  }
}

h4 {
  font-size: 2.4rem;

  @media (max-width: 1679px) {
    font-size: 2.2rem;
  }

  @media (max-width: 1439px) {
    font-size: 2rem;
  }
}

h5 {
  font-size: 2rem;

  @media (max-width: 1679px) {
    font-size: 1.8rem;
  }

  @media (max-width: 1439px) {
    font-size: 1.6rem;
  }
}

h6 {
  font-size: 1.8rem;

  @media (max-width: 1679px) {
    font-size: 1.6rem;
  }

  @media (max-width: 1439px) {
    font-size: 1.4rem;
  }
}

.dropdown {
  > a,
  > button {
    &::before {
      content: none !important;
    }

    &:after {
      content: "";
      border: 0;
      border-left: 0.5rem solid $gray8E;
      border-bottom: 0.5rem solid transparent;
      transform: rotate(90deg);
      margin-left: 0.8rem;
      border-top: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      vertical-align: middle;
      margin-top: 0.3rem;
    }
  }

  &-menu {
    border-radius: 1rem;
    width: 18.5rem;
    border: 0;
    background-color: $themeClr2F;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    padding: 0.5rem 0;
    margin-top: 0;

    @media (min-width: 1280px) {
      // transform: translateY(0) !important;
      // display: block !important;
      // transition: 0.3s all ease-in-out;
      // pointer-events: none;
      // top: calc(100% + 4rem) !important;
      // visibility: hidden;
      // opacity: 0;

      &::before {
        content: "";
        height: 2.2rem;
        width: 100%;
        display: block;
        top: -2.2rem;
        left: 0;
        position: absolute;
      }
    }
  }

  &::before {
    content: none !important;
  }

  &-item {
    font-size: 1.4rem;
    color: $white;
    padding: 1.1rem 2rem;
    border: 0 !important;
    position: relative;

    &:not(:last-child) {
      &::before {
        content: "";
        height: 1px;
        width: 90%;
        background: transparent
          linear-gradient(270deg, #f5f7f900 0%, #ffffffb8 53%, #f5f7f900 100%)
          0% 0% no-repeat padding-box;
        position: absolute;
        top: calc(100% - 0.5px);
        left: 5%;
        opacity: 0.15;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: $yellow;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $black;
    }

    &.active {
      background-color: transparent;
    }
  }

  // @media (min-width: 1280px) {
  //   &:hover {
  //     .dropdown-menu {
  //       visibility: visible;
  //       pointer-events: all;
  //       opacity: 1;
  //       transform: translate(0px, 0) !important;
  //       top: calc(100% + 2rem) !important;
  //     }
  //   }
  // }

  .dropdown-toggle {
    padding-left: 2rem;
    color: $white;
    justify-content: flex-start;

    &:before {
      content: "";
      height: 0.6rem;
      width: 1rem;
      background-image: url(./assets/images/icons/arrrow-down-thin-black.svg);
      background-position: center;
      background-size: contain;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      background-repeat: no-repeat;
      right: 1.5rem;
    }
  }

  @media (min-width: 1280px) {
    &:hover {
      .dropdown-toggle {
        &:before {
          content: "";
          transform: rotate(180deg);
          transform-origin: 5px 1px;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    &-menu {
      transform: translate(0px, 1rem) !important;
    }

    &.show {
      .dropdown-toggle {
        &:before {
          content: "";
          transform: rotate(180deg);
          transform-origin: 5px 1px;
        }
      }

      &-menu {
        transform: translate(0px, 0) !important;
      }
    }
  }

  &.show {
    > a,
    > button {
      color: $white;

      &:after {
        margin-top: -0.5rem;
        transform: rotate(-90deg);
        border-left-color: $white;
      }
    }
  }

  &:hover {
    @media (min-width: 1280px) {
      > a,
      > button {
        color: $white;

        &:after {
          margin-top: -0.5rem;
          transform: rotate(-90deg);
          border-left-color: $white;
        }
      }
    }
  }
}

.dark {
  .dropdown {
    button {
      min-width: 163px;
    }
    &-menu {
      background-color: $themeClr;
    }
  }
}

.modal-backdrop {
  --bs-backdrop-opacity: 1;
  // backdrop-filter: blur(13px);
  background-color: rgba($black, 0.5);
}

// perfect scrollbar
.scrollbar-container {
  padding: 0 !important;
  height: 100%;
  position: relative;

  .ps__thumb-y {
    background: $yellow;
    width: 3px !important;
  }

  .ps__rail-y {
    width: 7px !important;
  }
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background: transparent !important;
  border-radius: 8px;
  width: 6px !important;
}

.ps__thumb-x {
  background: $yellow;
  height: 3px !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background: $yellow;
  width: 3px !important;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background: $yellow;
  height: 3px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.commonInput {
  input {
    background-color: $black;
    height: 5rem;
    border-radius: 3rem;
    font-size: 1.4rem;
    color: $white;
    padding: 1rem 2rem;
    border: 0;

    &:focus {
      background-color: $black;
      box-shadow: none;
      color: $white;
    }

    &::placeholder {
      opacity: 0.5;
      color: $white900;
    }

    &::-webkit-input-placeholder {
      opacity: 0.5;
      color: $white900;
    }

    &:-moz-placeholder {
      opacity: 0.5;
      color: $white900;
    }
  }

  label {
    // color: $white;
    color: $white900;
    margin-bottom: 1.5rem;
  }

  .text-muted {
    color: $gray98 !important;
  }
}

.form-check {
  .form-check-input[type="checkbox"],
  .form-check-input[type="radio"] {
    height: 1.8rem;
    width: 1.8rem;
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    &:checked[type="checkbox"] {
      background-color: green;
      border-color: green;
    }
  }

  .form-check-input[type="radio"] {
    border: 1px solid green;
    height: 1.5rem;
    width: 1.5rem;

    &:checked {
      border-width: 2px;
    }
  }

  label {
    margin: 0;
    color: $white;
    cursor: pointer;
  }
}

.form-control {
  &:disabled {
    cursor: not-allowed;
  }
  &:not([type="checkbox"]):not([type="radio"]) {
    height: 5rem;
    border-radius: 3rem;
    background-color: $gray3D;
    font-size: 1.4rem;
    color: $white;
    padding: 1.6rem 2rem;
    border: 0;
    font-weight: 500;
    @extend %transition;
    overflow: clip;

    @media (max-width: 1279px) {
      height: 4.4rem;
    }

    &::placeholder {
      opacity: 1;
      color: $gray98;
    }

    &:-moz-placeholder {
      opacity: 1;
      color: $gray98;
    }

    &::-webkit-input-placeholder {
      opacity: 1;
      color: $gray98;
    }

    &:focus {
      box-shadow: none;
      // background-color: $gray98;
    }
  }
}

// :global {
.removeImg {
  height: 3rem;
  width: 3rem;
  color: $white;
  background-color: $red;
  font-weight: bold;
  border-radius: 50%;
  @extend %transition;
  opacity: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  overflow: hidden;
}
.imgprebox {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
// .profile_sec {
.profile-img {
  .profileImg {
    border-radius: 50%;
  }
  &:hover {
    backdrop-filter: blur(0.5rem);

    .removeImg {
      opacity: 1;
    }
  }
}

// .profile_sec {
.profile_banner {
  .profileImg {
    border-radius: 25px;
  }

  &:hover {
    backdrop-filter: blur(0.5rem);

    .removeImg {
      opacity: 1;
    }
  }
}

.profileImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

// }
// }
.slick {
  &-slider {
    @media (min-width: 1440px) {
      margin: 0 -1.5rem;
    }
  }

  &-slide {
    padding: 0 1.5rem;
    height: auto;

    // background-color: red;
    > div {
      height: 100%;
    }
  }

  &-arrow {
    position: absolute;
    height: 4.4rem;
    width: 4.4rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    transform: translateY(-50%);
    top: 50%;
    z-index: 1;
    font-size: 0;
    line-height: 0;
    box-shadow: 0 0 14px rgba($black, 0.5) !important;

    &.slick-prev {
      left: 0;

      @media (min-width: 1440px) {
        left: -0.7rem;
      }

      background-image: url(./assets/images/arrow-left.png);
    }

    &.slick-next {
      right: 0;

      @media (min-width: 1440px) {
        right: -0.7rem;
      }

      background-image: url(./assets/images/arrow-right.png);
    }

    @media (max-width: 1439px) {
      height: 3.4rem;
      width: 3.4rem;
    }

    @media (max-width: 991px) {
      height: 2.8rem;
      width: 2.8rem;
    }
  }

  &-track {
    display: flex;
  }
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: $white;
}

.PasswordCheckList {
  padding: 5px 22px !important;

  li {
    font-size: 14px;
    align-items: center;
    transition: all ease-in-out 0.5s;

    &.valid {
      transition: all ease-in-out 0.5s;
      display: none;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.error_field {
  &.success {
    span {
      color: $yellowD7;
    }
  }
  span {
    color: red;
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 1rem;
  }
}
.PhoneInputCountrySelectArrow {
  border: 0;
  width: 14px;
  height: 12px;
  background: url(./assets/images/country_dropdown.png) no-repeat;
  transform: none;
  opacity: 1;
  margin-top: 5px;
}
.drop_area.drop_zone {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  margin: 0;
}

.fractionModalStyle {
  .modal-content {
    overflow: visible;
    .modal-header {
      border-bottom: 1px solid $borderClr;
      padding-bottom: 26px;
      margin-bottom: 26px;
    }
    .btn-close {
      display: none;
    }
  }
  .btn_cancel {
    margin-top: 18px;
    border: 2px solid $borderClr;
    width: 100%;
    font-weight: 400;
    &:before {
      display: none;
    }
  }
}
.smallThumbs {
  .error_field {
    span {
      font-size: 1rem;
      line-height: 1.3rem;
      display: inline-block;
    }
  }
}

.download-doc {
  svg {
    position: relative;
    top: -2px;
    margin-left: 5px;
    path {
      fill: $yellow !important;
    }
  }
}
