@import "../../../../assets/_var.scss";
.Notification {
  &Head {
    padding: 0 2rem;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 2rem;
    h4 {
      font-weight: bold;
      color: $white;
      margin: 0;
      font-size: 2rem;
    }
    button {
      font-size: 1.2rem;
      color: $white900;
      text-transform: uppercase;
      @extend %BgGradient;
      border-radius: 3rem;
      padding: 0.7rem 1.4rem;
      font-weight: 500;
    }
  }
  &Item {
    display: flex;
    align-items: center;
    padding: 2rem 2rem !important;
    &Thumb {
      // margin-right: 1.4rem;
      flex: 0 0 7rem;
      max-width: 7rem;
      img {
        height: 5.6rem;
        width: 5.6rem;
        border-radius: 1rem;
        object-fit: cover;
      }
      video {
        height: 5.6rem;
        width: 5.6rem;
        border-radius: 1rem;
        object-fit: cover;
      }
    }
    &Content {
      position: relative;
      flex: 0 0 calc(100% - 7rem);
      max-width: calc(100% - 7rem);
      padding-right: 3rem;
      h6 {
        margin: 0;
        font-size: 1.4rem;
        line-height: 1.4;
        color: $white;
        font-weight: normal;
        white-space: normal;
      }
      span {
        font-size: 1.2rem;
        color: $gray98;
        font-weight: normal;
        white-space: normal;
        display: block;
      }
    }
    &[class*="unread"] {
      &:after {
        content: "";
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        position: absolute;
        right: 2rem;
        top: 2.4rem;
        background-color: $yellowDark2;
      }
      h6 {
        font-weight: 600;
      }
      // &Content {
      // }
    }
  }
  @media (max-width: 1679px) {
    &Head {
      h4 {
        font-size: 1.6rem;
      }
      button {
        padding: 0.4rem 1.4rem;
      }
    }
    &Item {
      display: flex;
      align-items: center;
      padding: 1.2rem 2rem !important;
      &Thumb {
        // margin-right: 1.4rem;
        flex: 0 0 5.5rem;
        max-width: 5.5rem;
        img {
          height: 4.5rem;
          width: 4.5rem;
        }
      }
      &Content {
        flex: 0 0 calc(100% - 5.5rem);
        max-width: calc(100% - 5.5rem);
        padding-right: 2rem;
        h6 {
          font-size: 1.2rem;
        }
        span {
          font-size: 1.2rem;
        }
      }
      &[class*="unread"] {
        &:after {
          height: 0.7rem;
          width: 0.7rem;
        }
      }
    }
  }
}

.NotificationItemContent {
  span {
    a {
      color: $white;
    }
  }
}
