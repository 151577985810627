@import '../../../assets/_var.scss';

.trending_nft {
    padding: 0 0 10rem 0;

    &_head {
        text-align: center;
        max-width: 102rem;
        margin: 0 auto;
        margin-bottom: 6rem;

        h2 {
            color: $white900;
            margin-bottom: 1.7rem;
        }

        p {
            color: $gray98;
            font-size: 1.4rem;
        }
    }
    @media (max-width:1679px) {
        padding:  0 0 7rem 0;
    }
    @media (max-width:1439px) {
        padding: 0 0 5rem 0;
    }
}