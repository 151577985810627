@import "../../../assets/_var.scss";

.howitworks {
  padding: 10rem 0;

  @media (min-width: 1610px) {
    padding: 20rem 0;
    margin-top: -10rem;
  }

  &_head {
    text-align: center;
    max-width: 102rem;
    margin: 0 auto;
    margin-bottom: 2.5rem;

    h2 {
      color: $white900;
      margin-bottom: 1.7rem;
    }

    p {
      color: $gray98;
      font-size: 1.4rem;
    }
  }

  @media (max-width: 767px) {
    padding: 20rem 0 5rem 0;
    margin-top: -20rem;
  }
}
