  @import "../../assets/_var.scss";

  .faq_Sec {
      margin-top: 4rem;

      &_head {
          font-size: 4.5rem;
          margin-bottom: 3rem;

          h2 {
              margin: 0;
              color: $white;
          }
      }

      :global {
          .accordion {
              &-item {
                  background-color: transparent;
                  border: none;
                  margin-top: 2rem;
                  border-radius: 1rem;

                  &:focus {
                      box-shadow: unset;
                      outline: none;
                  }

                  &:last-child {
                      margin-bottom: 10rem;
                  }
              }

              &-header {
                  border-radius: 1rem;

                  button {
                      position: relative;
                      background-color: #2F2E36;
                      font-size: 1.8rem;
                      color: $white;
                      font-weight: 600;
                      padding: 3rem 3.7rem;
                      padding-right: 6rem;
                      box-shadow: unset;
                      line-height: 2.2rem;

                      @media(max-width: 767px) {
                          font-size: 1.4rem;
                          padding: 2rem 6rem 2rem 2rem;
                      }

                      &::after {
                          content: "";
                          background-image: inherit;
                          position: absolute;
                          width: 12px;
                          height: 6px;
                          transform: translateY(-50%) rotate(90deg);
                          left: auto;
                          right: 3rem;
                          border-top: .6rem solid transparent;
                          border-bottom: .6rem solid transparent;
                          border-left: .6rem solid $white;
                          top: 50%;
                          transform-origin: .3rem;
                      }

                      &[aria-expanded="true"] {
                          &::after {
                            transform: translateY(-50%) rotate(-90deg);
                            //   margin-top: -1rem;
                          }
                      }
                  }
              }

              &-button {
                  border-radius: 1rem !important;

              }

              &-body {
                  background-color: $themeClr;
                  font-size: 1.4rem;
                  color: #989898;
                  padding: 2rem 20rem 0 3.7rem;

                  @media(max-width: 767px) {
                      padding: 2rem;
                      padding-bottom: 0;
                      font-size: 1.2rem;
                  }
              }
          }
      }
  }