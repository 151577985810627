// fonts
$baseFont: "Montserrat", sans-serif;
// colors
$themeClr: #1e1d24;
$themeClr27: #27272d;
$themeClr2F: #2f2e36;
$baseBgClr: #171717;
$white: #ffffff;
$white900: #fef6e7;
$black: #000000;
$lightgray: #3e3e3e;
$btngrey: #7d818b;
$gray100: #4a4a4a;
$gray99: #44434a;
$gray98: #989898;
$gray8E: #8e8d93;
$gray21: #212121;
$gray3D: #3d3c46;
$gray4D: #403f47;
$gray80: #808080;
$gray2D: #2d2d33;
$gray32: #323238;
$pageclr: #949494;
$yellow: #cdac5f;
$yellowD5: #d59111;
$yellowD6: #d6ba0f;
$yellowD7: #cf930f;
$yellowDark: #a97f37;
$yellowDark2: #ad760e;
$yellowDark3: #c6a457;
$yellowF6: #f6de62;
$borderClr: #5e5e60;
$red: #f00;
$redclr: #e54863;
$filterClr: #4a3c0f;
$borderClr2: #3e3e3e;
$borderClr3: #464646;
$green: #73bb15;
$parrotgreen: #08cc8c;
$borderBottom: rgba(255, 255, 255, 0.1);

// extends
%transition {
  transition: 0.3s all ease-in-out;
}

%commonTextGradient {
  background: transparent linear-gradient(180deg, $yellow 0%, $yellowDark 100%)
    0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

%BgGradient {
  background: transparent linear-gradient(180deg, $yellow 0%, $yellowDark 100%)
    0% 0% no-repeat padding-box;
}
