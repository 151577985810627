@import "../../../assets/_var.scss";
.banner_sec {
    padding: 5rem 0 0 0;
    @media(max-width: 991px) {
        padding: 5rem 0 0 0;
    }
    @media(max-width: 767px) {
        padding: 5rem 0 3rem 0;
    }
    &_content {
        h1 {
            max-width: 50rem;
            width: 100%;
            // font-size: 6rem;
            color: $white900;
            font-weight: 700;
            margin-bottom: 3.2rem;
            span {
                @extend %commonTextGradient;
            }
            @media(max-width: 991px) {
                max-width: 100%;
                // font-size: 4rem;
                margin-bottom: 2.2rem;
                text-align: center;
            }
            @media(max-width: 575px) {
                // font-size: 3rem;
                margin-bottom: 2rem;
            }
        }
        p {
            font-size: 2rem;
            font-weight: 500;
            color: $gray98 ;
            max-width: 56.1rem;
            width: 100%;
            border-bottom: 1px solid $lightgray;
            padding-bottom: 3rem;
            @media(max-width: 1679px) {
                font-size: 1.8rem;
            }
            @media(max-width: 991px) {
                max-width: 100%;
                text-align: center;
                font-size: 1.6rem;
            }
        }
    }
    &_text {
        display: flex;
        gap: 8rem;
        margin-top: 3rem;
        @media(max-width: 991px) {
            justify-content: center;
            @media(max-width: 575px) {
                text-align: center;
                display: block;
            }
        }
        p {
            font-size: 1.8rem;
            color: $gray98 ;
        }
        h2 {
            font-size: 4rem;
            font-weight: 700;
            color: $white;
            @media (max-width:1679px) {
                font-size: 3.4rem;
            }
            @media (max-width:1439px) {
                font-size: 3rem;
            }
            @media (max-width:1279px) {
                font-size: 2.8rem;
            }
        }
    }
    .bannersec_pic {
        max-width: 74rem;
        width: 100%;
        @media(max-width: 991px) {
            max-width: 60rem;
            margin: auto;
        }
    }
}