@import "../../../assets/_var.scss";

.commonModal {
  padding: 0 !important;

  p,
  span,
  a {
    color: $white;
  }

  .modal {
    &-dialog {
      padding: 3rem 1.5rem;
      width: 100%;
      max-width: 62.5rem;

      &:not(.modal-dialog-centered) {
        margin: 10rem auto 0;

        @media (max-width: 1679px) {
          margin: 1rem auto 0;
        }
      }
    }

    &-content {
      padding: 4rem 3rem;
      background-color: $themeClr2F;
      border-radius: 2.6rem;
      overflow: hidden;
      border: 0;

      @media (max-width: 1679px) {
        padding: 2rem;
      }
    }

    &-header {
      border: 0;
      padding: 0;

      .modal-title {
        padding: 0;
        color: $white;
        font-weight: 600;
        font-size: 2.2rem;
        margin-bottom: 0;

        @media (max-width: 359px) {
          font-size: 1.8rem;
        }

        .btn-close {
          filter: invert(1);
        }
      }

      .btn-close {
        position: absolute;
        padding: 0rem;
        background-image: url(../../../assets/images/cross.svg);
        background-color: $gray99;
        opacity: 1;
        border-radius: 50%;
        height: 4rem;
        width: 4rem;
        right: 3rem;
        top: 4rem;
        background-size: 2.2rem;
        transition: 0.3s all ease-in-out;
        z-index: 1;

        &:hover {
          transition: 0.6s all ease-in-out;
          transform: rotate(180deg);
        }

        @media (max-width: 1679px) {
          top: 3rem;
        }
      }
    }

    &-body {
      padding: 0;
    }
  }

  &.headerNone {
    .modal-title {
      display: none;
    }
  }

  &.creat_modal {
    .modal-dialog {
      max-width: 48.9rem;

      // .modal-content {
      //     padding: 3.8rem 3rem;
      //     border-radius: 3rem;
      // }

      .modal-header {
        .modal-title {
          font-size: 30px;
          font-weight: 700;
        }
      }

      .creat_headind {
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 5.3rem;
      }

      .modal-body {
        .form-control {
          background-color: $gray3D;
        }

        .form-label {
          font-size: 1.6rem;
          color: $white900;
        }

        .creat_form {
          margin-bottom: 5.8rem;
        }

        p.commonModal-subtitle {
          font-size: 3rem;
          font-weight: 600;
          margin-top: 3rem;
          margin-bottom: 4rem;
          line-height: 1.8rem;

          @media (max-width: 1279px) {
            font-size: 2.6rem;
            margin-bottom: 2.8rem;
          }

          @media (max-width: 575px) {
            font-size: 2.2rem;
            margin-bottom: 2rem;
          }
        }

        p {
          font-size: 1.4rem;
        }

        .form-check {
          display: flex;
          align-items: center;

          .form-check-input[type="checkbox"] {
            margin-top: 2rem;
            flex-shrink: 0;
            height: 1.8rem;
            width: 1.8rem;
            background-color: transparent;
            box-shadow: none;
            padding: 0;
            border-radius: 50% !important;
          }

          .form-check-input[type="checkbox"]:checked[type="checkbox"] {
            background-color: $yellowD7;
            border-color: $yellowD7;
          }
        }
      }
    }
  }

  &.forgotpass_modal {
    .modal-dialog {
      max-width: 48.9rem;

      .modal-header {
        .modal-title {
          font-size: 3rem;
          font-weight: 700;
          margin-bottom: 5.3rem;
          margin-top: -1rem;

          @media (max-width: 1279px) {
            font-size: 2.6rem;
            margin-bottom: 2.8rem;
          }

          @media (max-width: 575px) {
            font-size: 1.8rem;
            margin-bottom: 2rem;
          }

          p {
            font-size: 1.4rem;
          }
        }
      }

      .modal-body {
        .form-control {
          background-color: $gray3D;
          margin-bottom: 4.7rem;
        }

        .form-label {
          font-size: 1.6rem;
          color: $white900;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &.alert_modal {
    .modal-dialog {
      max-width: 48.9rem;

      .commonModal-subtitle {
        text-align: center;
        margin: 3.8rem 0;

        @media (max-width: 575px) {
          margin: 2.8rem 0;
        }

        img {
          max-width: 19.6rem;
          width: 100%;
          height: 17.3rem;
          margin: 0 auto;

          @media (max-width: 575px) {
            height: 10.3rem;
            max-width: 11.6rem;
          }
        }
      }

      .modal-body {
        p {
          font-size: 2.6rem;
          font-weight: 600;
          margin-bottom: 3.8rem;

          @media (max-width: 575px) {
            font-size: 2rem;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  &.verification_modal {
    .modal-dialog {
      max-width: 52rem;

      .modal-header {
        .modal-title {
          font-size: 30px;
          font-weight: 700;
        }
      }

      .creat_headind {
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 5.3rem;
      }

      .modal-body {
        .form-control {
          background-color: $gray3D;
        }

        .creat_form {
          margin-bottom: 5.8rem;
        }

        p.commonModal-subtitle {
          font-size: 3rem;
          font-weight: 600;
          margin-top: 3rem;
          margin-bottom: 4rem;
          line-height: 1.8rem;

          @media (max-width: 1279px) {
            font-size: 2.6rem;
            margin-bottom: 2.8rem;
          }

          @media (max-width: 575px) {
            font-size: 2.2rem;
            margin-bottom: 2rem;
          }
        }

        p {
          font-size: 1.4rem;
        }

        .signup_note {
          margin-top: 2rem;
          text-align: center;

          p {
            font-size: 1.6rem;
            color: $gray98;
            font-weight: 500;
            margin-bottom: 0;

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }

            @media (max-width: 479px) {
              font-size: 1.2rem;
            }

            a {
              color: $yellowD7;

              &:hover {
                color: $white;
              }
            }
          }
          .resend_block {
            display: flex;
            justify-content: center;
            span {
              position: absolute;
              left: 0;
              right: 0;
              top: 100%;
            }
          }
        }
      }
    }

    .verification_modal_otp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3rem;

      input {
        height: 5rem !important;
        width: 100% !important;
        border-radius: 2.5rem;
        font-size: 1.4rem;
        color: $gray98;
        flex: 0 0 calc(25% - 1.5rem);
        max-width: calc(25% - 1.5rem);
        background-color: $gray3D;
        border: 0;

        &:focus {
          outline: none;
        }
      }
    }

    .verification_modal_resend {
      display: flex;
      flex-direction: column-reverse;

      button {
        font-size: 1.6rem;
        font-weight: 600;
        color: $yellowD7;
        width: 80px;
        margin: 0 auto;

        &:disabled,
        &[disabled] {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}
