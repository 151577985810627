@import "../../assets/_var.scss";

.admin_sec {
    max-width: 80.5rem;
    margin: 3rem auto 20rem;

    @media(max-width: 1439px) {
        margin: 3rem auto 5rem;
    }

    @media(max-width: 767px) {
        max-width: 100%;
    }

    h2 {
        font-size: 4.5rem;
        color: $white900;
        margin-bottom: 5rem;
        font-weight: 700;

        @media(max-width: 1439px) {
            font-size: 4rem;
        }

        @media(max-width: 991px) {
            font-size: 3.2rem;
            margin-bottom: 3rem;
        }

        @media(max-width: 575px) {
            font-size: 3rem;
        }
    }

    &_block {
        background-color: $gray3D;
        border-radius: 1.5rem;
        padding: 3rem;

        @media(max-width: 575px) {
            padding: 1.5rem;
        }

        &_detail {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            h6 {
                color: $white900;
                font-weight: 600;
            }
        }

        p {
            max-width: 57.2rem;
            color: $gray98;
            font-size: 1.6rem;

            @media(max-width: 1439px) {
                font-size: 1.4rem;
            }

            @media(max-width: 767px) {
                max-width: 100%;
            }

        }
    }

    &_period {
        text-align: center;
        margin-top: 3rem;
        padding: 5rem 15rem;
        background-color: $themeClr27;
        border-radius: 1.5rem;

        @media(max-width: 767px) {
            padding: 5rem;
        }

        @media(max-width: 575px) {
            padding: 1.5rem;
        }

        h6 {
            margin: 5rem 0 1.6rem;
            color: $white900;
            font-weight: 600;
        }

        p {
            font-size: 1.6rem;
            color: $gray98;

            @media(max-width: 1439px) {
                font-size: 1.4rem;
            }
        }
    }
}