@import '../../../assets/_var.scss';

.common_search {
    position: relative;

    :global {
        .form-control {
            &:not([type="checkbox"]):not([type="radio"]) {
                width: 100%;
                max-width: 100%;
                padding-left: 4rem;
                background-color: $themeClr;

                &::placeholder {
                    color: $gray80;
                }

                &:-moz-placeholder {
                    color: $gray80;
                }

                &::-webkit-input-placeholder {
                    color: $gray80;
                }
            }
        }
    }

    button {
        left: 2rem;
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
    }
}