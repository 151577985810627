@import '../../../assets/_var.scss';
.kyc {
    padding: 5rem 3rem;
    border-radius: 2.6rem;
    background-color: $themeClr2F;
    margin-top: 3rem;
    text-align: center;
    &_lottie {
        >div {
            display: table;
            margin: 0 auto;
            margin-bottom: 2.4rem;
            @media (max-width:1279px) {
                width: 10rem !important;
            }
        }
    }
    h4 {
        color: $white;
        @media (min-width:1680px) {
            font-size: 2.6rem;
        }
    }
    h4,
    p {
        max-width: 62rem;
        margin: auto;
    }
    p {
        font-size: 1.4rem;
        color: $gray98;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: 500;
    }
    &_footer {
        background-color: $themeClr;
        padding: 2.1rem 3rem;
        border-radius: 2.6rem;
        max-width: 58.4rem;
        margin: 0 auto;
        text-align: left;
        span {
            font-size: 1.4rem;
            color: $gray98;
            display: block;
            margin-bottom: .5rem;
        }
        h6 {
            // color: $white;
            font-weight: 600;
            margin: 0;
            font-size: 1.6rem;
            svg {
                vertical-align: middle;
            }
            @media (max-width:1439px) {
                font-size: 1.4rem;
            }
        }
        &_box_approved {
            color: $green;
        }
        &_box_submitted {
            color: $yellowF6;
        }
        &_box_rejected {
            color: $red;
        }
        >div {
            >div {
                &:nth-child(2) {
                    position: relative;
                    @media (min-width:1280px) {
                        flex: 0 0 36%;
                    }
                    div {
                        display: table;
                        margin: 0 auto;
                    }
                    &:after,
                    &:before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        background-color: $white;
                        opacity: .1;
                        @media (min-width:576px) {
                            transform: translateY(-50%);
                            top: 50%;
                        }
                        height: 100%;
                        left: 0;
                    }
                    &:after {
                        left: auto;
                        right: 0;
                    }
                }
                &:nth-child(3) {
                    div {
                        display: table;
                        margin-left: auto;
                    }
                }
            }
            @media (max-width:575px) {
                flex-direction: column;
                // flex-wrap: wrap;
                text-align: center;
                > div {
                    &:nth-child(2) {
                        margin: 2rem 0;
                        &:after,
                        &:before {
                            width: 100%;
                            height: 1px;
                            top: 0;
                        }
                        &:after {
                            top: auto;
                            bottom: 0;
                        }
                        > div {
                            padding: 2rem 0;
                            
                            display: block;
                        }
                    }
                    &:nth-child(3) {
                        > div {
                            margin: 0  auto;
                            display: block;
                        }
                    }
                }
               
            }
        }
    }
    :global {
        .commonBtn {
            max-width: 43rem;
            width: 100%;
            margin-top: 5rem;
        }
    }
}