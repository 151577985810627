@import '../../../../assets/_var.scss';
.n_counter {
    margin-top: 6rem;
    &_main {
        &_left {
            &_inner {
                font-size: 5.2rem;
                font-weight: bold;
                @extend %commonTextGradient;
                display: inline-block;
            }
            span {
                font-weight: 600;
                color: $white;
                font-size: 3.1rem;
                display: inline-block;
                padding-left: 1rem;
            }
        }
        h4 {
            font-size: 2rem;
            font-weight: 500;
            color: $white;
        }
    }
    >div {
        >div {
            &:nth-child(2) {
                > div {
                   padding-left: 3rem;
                margin: 0 auto;
               }
            }
            &:nth-child(2),
            &:last-child {
                >div {
                    display: table;                   
                }
            }
             &:last-child {
                >div {
                    margin-left: auto;
                   
                }
            }
        }
    }

    @media (max-width:1679px) {
        margin-top: 4rem;
        &_main {
            &_left {
                &_inner {
                    font-size: 4.2rem; 
                }
                span { 
                    font-size: 2.4rem; 
                }
            }
            h4 {
                font-size: 1.6rem; 
            }
        }
        >div {
            >div {
                &:nth-child(2) {
                   > div {
                    padding-right: 0rem;
                    padding-left: 3rem;
                   }
                }
               
            }
        }
    }

    @media (max-width:1439px) {
        margin-top: 4rem;
        &_main {
            &_left {
                &_inner {
                    font-size: 3.8rem; 
                }
                span { 
                    font-size: 2rem; 
                }
            }
            h4 {
                font-size: 1.4rem; 
            }
        }
        >div {
            >div {
                &:nth-child(2) {
                   > div {
                     padding-left: 3rem;
                   }
                } 
            }
        }
    }
    @media (max-width:1279px) {
        margin-top: 4rem;
        &_main {
            &_left {
                &_inner {
                    font-size: 3.4rem; 
                }
                span { 
                    font-size: 1.8rem; 
                }
            }
            h4 {
                font-size: 1.4rem; 
            }
        }
        >div {
            >div {
                &:nth-child(2) {
                    > div {
                       padding-left: 0;
                   }
                }
                &:nth-child(2) , &:last-child {
                    >div {
                         margin-left: inherit;
                    }
                }                
            }
        }
    }
    @media (max-width:991px) {
        margin-top: 3rem;
        &_main {
            &_left {
                &_inner {
                    font-size: 2.8rem; 
                }
                span { 
                    font-size: 1.6rem; 
                }
            }
            h4 {
                font-size: 1.2rem; 
            }
        }
    }
    @media (max-width:767px) {
        margin-top: 3rem;
        &_main {
             &_left {
                &_inner {
                    font-size: 2.8rem; 
                }
                span { 
                    font-size: 1.6rem; 
                    padding-left: .5rem;
                }
                text-align: center;
            }
            h4 {
                font-size: 1.2rem; 
            }
        }
        >div {
            >div {
                &:nth-child(2) {
                    > div {
                       padding-left: 0;
                   }
                }
                &:nth-child(2) , &:last-child {
                    >div {
                         margin:  0 auto;
                    }
                }                
            }
        }
    }
}