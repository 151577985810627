@import "../../assets/_var.scss";

.contact_sec {
    margin: 4rem 0 10rem;

    @media(max-width: 991px) {
        margin: 3rem 0;
    }

    @media(max-width: 479px) {
        margin: 2rem 0;
    }

    :global {
        label {
            color: $white900;
        }

        .commonBtn {
            max-width: 42.9rem;
            width: 100%;

            @media(max-width: 479px) {
                max-width: 100%;
            }
        }

        textarea {
            height: 14.95rem !important;

            @media(max-width:767px) {
                .row {
                    // background-color: red;
                    margin: 0 -1rem;

                    >div {
                        padding: 0 1rem;
                    }
                }

                textarea {
                    height: auto;
                }
            }

        }
    }

    &_head {
        h2 {
            color: $white900;
            margin-bottom: 2rem;
            line-height: 3.5rem;

            @media(max-width: 991px) {
                margin: 3rem 0;
            }
        }

        p {
            color: $gray98;
            font-weight: 500;
            margin-bottom: 2rem;
        }

        .hl {
            background-color: #3E3E3E;
            height: 1px;
            width: 100%;
            margin: 2rem 0 3rem;
        }
    }

    &_left {
        display: flex;
        align-items: center;
        align-items: center;

        h3 {
            color: $white900;
            font-size: 2.6rem;
            margin-bottom: 1rem;
            line-height: 1.9rem;
        }

        P {
            font-size: 1.4rem;
            // font-weight: 200;
        }

        &_data {
            display: flex;
            align-items: center;

            h4 {
                margin-bottom: 0.5rem;
            }

            h4,
            p {
                margin-bottom: 0;
            }

            &_img {
                position: relative;
                margin-right: 2rem;
                z-index: 1;
                width: 5rem;
                height: 5rem;
                background-color: $gray3D;
                border-radius: 50%;
                z-index: -1;
                flex-shrink: 0;
                // padding: 0.2rem 1rem;
                justify-content: center;
                display: flex;
                align-items: center;

                img {
                    // width: 2.2rem;
                    height: 2rem;
                }
            }

            &_info {
                color: $white900;

                h4 {
                    font-size: 1.8rem;
                    font-weight: 500;
                }

                a {
                    color: $gray98;
                    font-size: 1.4rem;
                }
            }
        }

        &_map {
            border-radius: 3rem;
            height: 14.5rem;
            background-color: $gray3D;
            margin-top: 3.9rem;
            background-image: url(../../assets/images/map.png);
            object-fit: cover;
            background-size: cover;
            max-width: 59.4rem;
            width: 100%;
        }
    }

}