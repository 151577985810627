@import "../../../assets/_var.scss";

.box_sec {
    >div {
        >div {
            &:first-child {
                padding-top: 0;
            }
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }

    @media(max-width: 1439px) {
        >div {
            >div {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
        }
    }

    &_content {
        display: table;

        h2 {
            font-size: 4.1rem;
            color: $white900;
            font-weight: 700;
            margin-bottom: 2rem;

            @media(max-width: 991px) {
                max-width: 100%;
                font-size: 3.8rem;
                margin-bottom: 2rem;
                text-align: center;
            }

            @media(max-width: 575px) {
                font-size: 3rem;
            }
        }

        p {
            font-size: 1.4rem;
            font-weight: 500;
            color: $gray98 ;
            max-width: 67.4rem;
            width: 100%;
            padding-bottom: 1rem;
            line-height: 2.3rem;

            @media(max-width: 991px) {
                max-width: 100%;
                text-align: center;
            }
        }
    }

    .boxsec_pic {
        display: table;

        img {
            max-width: 53.5rem;
            width: 100%;
            box-shadow: 0 0 54px rgb(0 0 0 / 50%);
            border-radius: 3rem;
        }

        @media(max-width: 991px) {
            margin: 0 auto;
            margin-top: 2rem;

            img {
                max-width: 45rem;
            }
        }
    }
}