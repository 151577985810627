@import "../../../assets/_var.scss";

.alert_modal {
    .modal-body {
        p {
            @media(max-width: 1679px) {
                font-size: 2.2rem !important;
                font-weight: 500 !important;
            }

            @media(max-width: 991px) {
                font-size: 1.8rem !important;
                font-weight: 400 !important;
            }

            @media(max-width: 767px) {
                font-size: 1.4rem !important;
            }
        }
    }
}