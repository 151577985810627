@import "../../assets/_var.scss";

.support {
  margin: 4rem 0 20rem;

  h2 {
    font-size: 4.5rem;
    color: $white;

    @media (max-width: 1199px) {
      font-size: 3.5rem;
    }

    @media (max-width: 991px) {
      font-size: 3rem;
    }

    @media (max-width: 767px) {
      font-size: 2.5rem;
    }
  }

  &_right {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    &_simple_btn {
      background: linear-gradient(
        100deg,
        $yellowDark 0%,
        $yellow 100%
      ) !important;
      margin-left: 2rem;
      height: 5rem;
      width: 159px;
      span {
        justify-content: center;
      }

      @media (max-width: 575px) {
        height: 5rem;
      }

      &::before {
        display: none;
      }
    }
  }

  &_sec {
    background: $gray3D;
    border-radius: 2.6rem;
    padding: 3rem;
    margin-top: 2rem;
    cursor: pointer;

    &_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      border-radius: 1rem;

      h6 {
        color: $white900;
        margin-bottom: 1.4rem;
      }

      p {
        margin: 1.4rem 0 0;
      }

      &:last-child {
        h6 {
          font-weight: 400;
          margin-bottom: 0;
        }
      }

      .btn-bordered {
        height: 4rem;
      }
      button {
        span {
          text-transform: capitalize;
        }
      }
    }

    hr {
      margin: 2rem 0;
    }
  }
  :global {
    .customer-all-ticket {
      .dropdown-toggle.btn.btn-primary {
        min-width: 214px;

        @media (max-width: 768px) {
          min-width: auto;
        }
      }
    }
  }
}
