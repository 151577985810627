@import "../../../assets/_var.scss";

:global {
  .pagination {
    justify-content: center;
    margin-top: 2rem;

    .page-item {
      .page-link {
        background: transparent;
        border: none;
        color: $pageclr;
        font-size: 1.8rem;

        &:focus {
          box-shadow: unset;
        }
      }

      &.active {
        .page-link {
          color: $white;
        }
      }

      &:first-child,
      &:last-child {
        width: 4rem;
        text-align: center;
        border: 1px solid $yellowD7;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 6px;
        border-radius: 50%;
        background-position: center;
      }

      &:first-child {
        background-image: url(../../../assets/images/back_arrow.png);
      }

      &:last-child {
        background-image: url(../../../assets/images/front-arrow.png);
      }
    }
  }
}
