@import "../../assets/_var.scss";

.admin_sec {
    max-width: 80.5rem;
    margin: 3rem auto 20rem;

    @media (max-width: 1439px) {
        margin: 3rem auto 5rem;
    }

    @media (max-width: 767px) {
        max-width: 100%;
    }

    h2 {
        font-size: 4.5rem;
        color: $white900;
        margin-bottom: 5rem;
        font-weight: 700;

        @media (max-width: 1439px) {
            font-size: 4rem;
        }

        @media (max-width: 991px) {
            font-size: 3.2rem;
            margin-bottom: 3rem;
        }

        @media (max-width: 575px) {
            font-size: 3rem;
        }
    }

    &_block {
        background-color: $gray3D;
        border-radius: 1.5rem;
        padding: 3rem;

        @media (max-width: 575px) {
            padding: 1.5rem;
        }

        &_detail {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            h6 {
                font-weight: 600;
                color: $white900;
            }
            button{
                span{
                    text-transform: capitalize;
                }
            }
        }

        p {
            max-width: 57.2rem;
            color: $gray98;
            font-size: 1.6rem;

            @media (max-width: 1439px) {
                font-size: 1.4rem;
            }

            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }

    &_period {
        margin-top: 3rem;
        padding: 3rem;
        background-color: $gray3D;
        border-radius: 1.5rem;

        @media (max-width: 767px) {
            padding: 5rem;
        }

        @media (max-width: 575px) {
            padding: 1.5rem;
        }

        h6 {
            margin-bottom: 1.6rem;
            color: $white900;
            font-weight: 600;
        }

        p {
            font-size: 1.6rem;
            color: $gray98;
            margin-bottom: 2rem;

            @media (max-width: 1439px) {
                font-size: 1.4rem;
            }
        }
        &_reply{
            font-weight: 700;
            color: $white !important;
        }
    }
    &_period1 {
        text-align: center;
        margin-top: 3rem;
        padding: 5rem 15rem;
        background-color: $themeClr27;
        border-radius: 1.5rem;

        @media (max-width: 767px) {
            padding: 5rem;
        }

        @media (max-width: 575px) {
            padding: 1.5rem;
        }

        h6 {
            margin: 5rem 0 1.6rem;
            color: $white900;
            font-weight: 600;
        }

        p {
            font-size: 1.6rem;
            color: $gray98;

            @media (max-width: 1439px) {
                font-size: 1.4rem;
            }
        }
    }
    &_user_response {
        min-height: 8rem !important;
        background-color: #4b4a56 !important;
        color: #fff !important;
        overflow: auto !important;
        margin-bottom: 2.5rem;
        border-radius: 2rem !important;
    }
    &_send_response {
        background-color: #4b4a56;
        border: 1px solid #5e5e60;
        border-radius: 25px;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 13px 0;
        text-align: center;
        width: 100%;
    }
}
