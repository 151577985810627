@import "../../../assets/_var.scss";

.commonBtn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  border: 1px solid transparent;
  border-radius: 3rem;
  padding: 1.2rem 2.45rem;
  font-weight: 600;
  color: $white;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  @extend %transition;

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    @extend %BgGradient;
    @extend %transition;
    border-radius: 3rem;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  > span {
    &.icon {
      margin-right: 2rem;
      flex: none;
    }

    svg {
      path,
      polygon {
        transition: 0.3s all ease-in-out;
        fill: $yellowDark;
      }

      @media (max-width: 1679px) {
        width: 2rem;
      }
    }
  }

  &:focus {
    box-shadow: unset;
    outline: unset;
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    border: 1px solid $borderClr;
    color: $white;

    > span {
      svg {
        path,
        polygon {
          fill: $white;
        }
      }
    }
  }

  &.btn-bordered {
    border: 1px solid $borderClr;

    &::before {
      opacity: 0;
    }

    &:hover {
      border: 1px solid transparent;

      &::before {
        opacity: 1;
      }
    }
  }

  &.btn-bordered-red {
    border: 1px solid $redclr;
    color: $redclr;

    &::before {
      opacity: 0;
      background: $redclr;
    }

    &:hover {
      border: 1px solid $redclr;
      color: $white;

      &::before {
        opacity: 1;
      }
    }
  }

  &.btn-bordered-yellow {
    border: 1px solid $yellowD7;
    color: $yellowD7;

    &::before {
      opacity: 0;
      background: $yellowD7;
    }

    &:hover {
      color: $white;
      border: 1px solid $yellowD7;

      &::before {
        opacity: 1;
      }
    }
  }

  &.btn-bordered-green {
    border: 1px solid $parrotgreen;
    color: $parrotgreen;

    &::before {
      opacity: 0;
      background: $parrotgreen;
    }

    &:hover {
      color: $white;
      border: 1px solid $parrotgreen;

      &::before {
        opacity: 1;
      }
    }
  }

  &.btn-small {
    padding: 0.7rem 2.15rem;
    font-size: 1.6rem;
    // vertical-align: middle;
  }

  // @media (max-width:1679px) {
  //     font-size: 1.4rem;
  //     padding: 1rem 2rem;
  //     &.btn-small {
  //         padding: .8rem 1.5rem;
  //         font-size: 1.4rem;
  //         // vertical-align: middle;
  //     }
  // }
  // @media (max-width:1439px) {
  //     font-size: 1.4rem;
  //     padding: 0.6rem 2rem;
  // }
  &.disabled,
  &:disabled {
    pointer-events: none;
    cursor: none;
    opacity: 0.5;
  }

  @media (max-width: 1679px) {
    font-size: 1.4rem;
  }

  @media (max-width: 1439px) {
    padding: 0.8rem 1.5rem;
  }
  &.fullWidth {
    width: 100%;
  }
}
