@import "../../../assets/_var.scss";
.fractionalize {
  &_approved {
    color: $green;
  }
  &_submitted {
    color: $yellowF6;
  }
  &_rejected {
    color: $red;
  }
  &_actions {
    &_buttonStyle {
      margin-left: 10px;
      :global {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    &_buttonDisable {
      :global {
        svg {
          path {
            opacity: 0.3;
          }
        }
      }
    }
  }
  :global {
    .fractionalize_approved {
      color: $green;
    }
    .fractionalize_rejected {
      color: $red;
    }
    .fractionalize_pending {
      color: $yellowF6;
    }
  }
}

.dividendModal {
  :global {
    .modal-content {
      max-width: 498px;
      .btn-close {
        top: 0.2rem;
        right: 0;
      }
      .modal-header {
        position: relative;
        padding-bottom: 2.5rem;
        margin-bottom: 2.4rem;
        &::before {
          position: absolute;
          content: "";
          width: 100%;
          bottom: 0px;
          left: 0px;
          height: 1px;
          border-top: 1px solid $borderClr2;
        }
      }
    }
    .form-label {
      color: $white900;
    }
    .form-control {
      font-size: 1.8rem !important;
      color: #cf930f !important;
    }
  }
  &_nftInfo {
    padding-left: 15px;
    img {
      width: 56px;
      height: 56px;
      border-radius: 1rem;
      object-fit: cover;
      background-position: center;
      padding: 0;
    }

    &_detail {
      width: calc(100% - 56px);
      margin-bottom: 4rem;

      h4 {
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: $white900;
      }
      p {
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $gray98;
      }
    }
  }
  &_inputStyle {
    &_xar {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
    }
  }
  &_nftDetails {
    margin-top: 1.8rem;

    ul {
      text-align: center;
      margin-bottom: 1.8rem;
      li {
        font-style: 1.4rem;
        line-height: 2.8rem;
        color: $white;
        span {
          color: $yellowD7;
        }
      }
    }
  }
  h6 {
    color: $yellowD7;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
