@import '../../../assets/_var.scss';
.about {
    position: relative;
    overflow: hidden;
    padding: 10rem 0;

    &_left {
        position: relative;
        z-index: 1;
        &_inner {
            max-width: 56rem;
            width: 100%;
            overflow: hidden;
            position: relative;
            height: 53rem;
            // padding-top: 3rem;
            // background: #000;
        }
        &_lottie {
            width: 110rem;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            margin-top: 1rem;
            // svg {
            //     width: 60rem !important;
            // }
        }
        &::before {
            content: "";
            height: 69rem;
            width: 69rem;
            // height: 100%;
            // width: 100%;
            position: absolute;
            left: -6rem;
            top: -5rem;
            background-image: url(../../../assets/images/grid.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            z-index: -1;
            pointer-events: none;
        }
        @media (max-width:1679px) {
            &_inner {
                max-width: 43rem;
                height: 40rem;
                // background-color: red;
            }
            &_lottie {
                width: 83rem;
                
            }
            &::before {
                content: "";
                height: 59rem;
                width: 59rem;  
            }
        }
        @media (max-width:1439px) {
            
            &::before {
                content: "";
                height: 53rem;
                width: 53rem;  
                left: -1rem;
            }
        }
        @media (max-width:991px) {
            
            &_lottie {
                width: 63rem;
                
            }
            &:before {
                height: 43rem;
                width: 43rem;
                left: 0;
                top: 0;
            }
        }
        @media (max-width:767px) {
           &_inner,  &_lottie  {
               margin: 0 auto;
               display: table;
            }
            &:before {
                content: none;
            }
           
           
        }
    }

    &_right {
        h2 {
            margin-bottom: 1.5rem;
            span {
                font-weight: normal;
                color: $yellowDark3;
                font-size: 1.6rem;
                display: block;
                padding-bottom: 1rem;
            }
            color: $white;
        }
        p {
            color: $gray98;
            font-size: 1.4rem;
            font-weight: 500;
            margin-bottom: 2.5rem;
            &:last-of-type {
                margin-bottom: 4rem;
            }
        }
        @media (max-width:767px) { 
            text-align: center;
        }
    }
    @media (max-width:1679px) {
        padding: 7rem 0;
    }
    @media (max-width:1439px) {
        padding: 7rem 0 0;
    }
    @media (max-width:991px) {
        padding: 7rem 0 0;
    }
}