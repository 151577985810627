@import "../../assets/_var.scss";

.update {
  opacity: 0;
  position: absolute !important;
}
.fraction_sec {
  margin: 4rem 0 4.4rem 0;

  @media (max-width: 1679px) {
    margin: 8rem 0;
  }

  @media (max-width: 991px) {
    margin: 2rem 0;
  }

  &_head {
    h2 {
      // margin-top: 4rem;
      color: $white;
      margin-bottom: 2rem;
    }

    p {
      color: $gray98;
      font-weight: 500;
      margin-bottom: 2rem;
    }

    .hl {
      background-color: #3e3e3e;
      height: 1px;
      width: 100%;
      margin: 2rem 0 3rem;
    }
  }

  :global {
    label {
      margin-top: 1rem;
      color: $white900;
    }

    .dropdown {
      > div {
        width: 100%;
      }
    }
  }

  .dropdown_menu {
    border-radius: 3rem;
    background-color: $gray3D;
    font-size: 1.4rem;
    color: $white;
    padding: 1.45rem 2rem;
    border: 0;
    font-weight: 500;
    width: 100%;
    text-align: left;

    &:active {
      background-color: $gray3D;
      border: 0;
    }

    > div {
      width: 100%;
    }

    &:after {
      position: absolute;
      right: 2.5rem;
      top: 2rem;
    }
  }

  &_left {
    :global {
      label {
        margin-top: 1rem;
        color: $white900;
      }

      form {
        textarea {
          height: 19.5rem !important;

          @media (max-width: 767px) {
            .row {
              // background-color: red;
              margin: 0 -1rem;

              > div {
                padding: 0 1rem;
              }
            }

            textarea {
              height: 3.3rem !important;
            }
          }
        }
      }
    }
    &_hasTextarea {
      textarea {
        height: 104px !important;
      }
    }
  }

  &_right {
    :global {
      label {
        margin-top: 1rem;
      }
    }

    .upload_handle {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      border: 1px dashed $gray98;
      background-color: $gray3D;
      // padding: 6rem 4rem;
      height: 29.4rem;
      border-radius: 2.5rem;
      cursor: pointer;
      overflow: hidden;

      &:active {
        background-color: #3e3e3e !important;
        border-color: unset;
      }

      .removeImg {
        opacity: 1;
        height: 3rem;
        width: 3rem;
        color: $white;
        background-color: $red;
        font-weight: bold;
        border-radius: 50%;
        @extend %transition;
      }

      &_img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        position: absolute;
        height: 29.4rem;
        width: 100%;
        padding: 0 1rem;

        img {
          width: 4rem;
        }

        p {
          font-size: 1.4rem;
          color: $white900;

          span {
            color: $yellowD7;
          }
        }

        h4 {
          font-size: 1.4rem;
          color: $white900;
          line-height: 1.2;

          span {
            font-weight: 400;
          }
        }

        img {
          width: 4rem;
        }

        &_input {
          position: absolute;
          height: 100% !important;
          width: 100% !important;
          border-radius: 2.5rem;
          cursor: pointer;
          opacity: 0;
          pointer-events: all;
          // background-color: red !important;
          // opacity: 1;
          // visibility: visible;
        }
      }

      &:hover {
        .update {
          z-index: -1;
        }
        .removeImg {
          opacity: 1;
        }
      }
      :global {
        .error_field {
          position: absolute;
          top: 100%;
        }
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
      }
    }

    .upload_handle_child {
      position: relative;
      border: 1px dashed $gray98;
      background-color: $gray3D;
      height: 14.95rem;
      border-radius: 2.5rem;
      cursor: pointer;
      height: 9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &_removeBtn {
        position: absolute;
        z-index: 10;
        top: 10px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: $red;
          }
        }
      }

      @media (max-width: 991px) {
        margin-top: 1rem;
      }

      &_icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        position: relative;
        height: 100%;
        width: 100%;

        img {
          width: 3rem;
        }

        input {
          width: 100%;
          height: 100% !important;
          pointer-events: all;
          position: absolute;
          opacity: 0;
        }
      }

      .removeImg {
        opacity: 1 !important;
        height: 3rem;
        width: 3rem;
        color: $white;
        background-color: $red;
        font-weight: bold;
        border-radius: 50%;
        @extend %transition;
      }

      &:hover {
        .update {
          z-index: -1;
        }
        .removeImg {
          opacity: 1;
        }
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
      }
    }
    &_uploadDoc {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 1.1rem 1.4rem 1.1rem 1.8rem;
      border-radius: 3rem;
      background-color: #3d3c46;

      p {
        margin-bottom: 0;
        font-size: 1.4rem;
      }
    }
  }

  :global {
    .commonBtn {
      max-width: 42.9rem;
      width: 100%;

      @media (max-width: 479px) {
        max-width: 100%;

        span {
          font-size: 1.2rem;
        }
      }
    }
  }
  h3{   
    margin-top: 2.2rem;
    margin-bottom: 1.4rem;
    font-size: 2rem;
    color: $white900;
    font-weight: 600;
  }
}

.owner_doc {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_left {
    flex: 0 0 calc(75% - 2rem);
    max-width: calc(75% - 2rem);
  }

  &_right {
    flex: 0 0 25%;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    border: 1px solid $borderClr;
    border-radius: 3rem;
    padding: 1.2rem 2.45rem;
    font-weight: 600;
    color: $white;
    overflow: hidden;
    white-space: nowrap;
    z-index: 1;
    cursor: pointer;

    @media (max-width: 1279px) {
      white-space: nowrap;
      font-size: 1.4rem;
      padding: 1.1rem 2rem;
    }

    @media (max-width: 991px) {
      font-size: 1.2rem;
    }

    @media (max-width: 479px) {
      flex: 0 0 27%;
      font-size: 1rem;
      padding: 1.2rem 1rem;
    }

    &::before {
      @extend %transition;
      content: "";
      height: 100%;
      width: 100%;
      border-radius: 3rem;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      @extend %BgGradient;
      opacity: 0;
      z-index: -1;
    }

    &_input {
      height: 100% !important;
      width: 100%;
      border-radius: 3rem !important;
      z-index: 1;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  input {
    &::placeholder {
      color: $white !important;
    }

    &:active {
      background-color: $gray3D;
    }
  }
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.img {
  display: block;
  width: auto;
  height: 100%;
}
.fractionRequest {
  min-height: 100px;

  h3 {
    font: normal normal normal 16px/32px Montserrat;
    color: $white;
  }

  .dropdown_menu {
    border-radius: 3rem;
    background-color: $gray3D;
    font-size: 1.4rem;
    color: $white;
    padding: 1.45rem 2rem;
    border: 0;
    font-weight: 500;
    width: 100%;
    text-align: left;

    &:active {
      background-color: $gray3D;
      border: 0;
    }

    > div {
      width: 100%;
    }

    &:after {
      position: absolute;
      right: 2.5rem;
      top: 2rem;
    }
  }
  .dropdownList {
    width: 100%;
    max-height: 200px;
    border: 1px solid $borderClr2;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $gray3D;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $gray3D;
    }
  }
}

.docUpload {
  position: relative;
  margin-bottom: 20px;

  &_input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1.45rem 2rem;
    border-radius: 3rem;
    background-color: #3d3c46;

    p {
      margin-bottom: 0;
      font: normal normal medium 14px/18px Montserrat;
      color: $gray98;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
