@import "../../../assets/_var.scss";

.simpleBtn {
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
    border: 1px solid transparent;
    border-radius: 3rem;
    padding: 0.8rem 2rem 0.8rem 1rem;
    font-weight: 400;
    color: $white;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    border: 1px solid transparent;
    @extend %transition;

    &::before {
        content: "";
        height: 100%;
        width: 100%;
        // background: transparent linear-gradient(287deg, $yellow 0%, $yellowDark 100%) 0% 0% no-repeat padding-box;
        background-color: $gray3D;
        @extend %transition;
        border-radius: 3rem;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    >span {
        flex-shrink: 0;

        &.icon {
            margin-right: 2rem;
            flex: none;
        }

        svg {
            width: 3.3rem;

            path,
            polygon {
                transition: .3s all ease-in-out;
            }

            // @media (max-width:1679px) {
            //     width: 2rem;
            // }
        }
        &:last-child {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-flex;
            flex: 1 auto;
    white-space: nowrap;
        }
    }

    &:focus {
        box-shadow: unset;
        outline: unset;
    }

    &:hover {
        &:before {
            opacity: 0;
        }

        border: 1px solid $borderClr;
        color: $white;

        >span {
            // svg {

            //     path,
            //     polygon {
            //         fill: $white;
            //     }
            // }
        }
    }

    &.btn-bordered {
        border: 1px solid $borderClr;

        &::before {
            opacity: 0;
        }

        &:hover {
            border: 1px solid transparent;

            &::before {
                opacity: 1;
            }
        }
    }

    &.btn-small {
        padding: .7rem 2.15rem;
        font-size: 1.6rem;
    }

    &.disabled {
        pointer-events: none;
        opacity: .5;
    }

    @media (max-width:1679px) {
        font-size: 1.4rem;
    }

    @media (max-width:1439px) {
        padding: 0.8rem 1rem;
        font-size: 1.2rem;
    }
}