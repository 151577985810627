@import "../../../assets/_var.scss";

.Confirm_wallet_modal {
  z-index: 9999;
  .modal-dialog {
    max-width: 49rem;
    margin: 0 auto;
  }
  .confirmBtnWrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
  }
  .confirmText {
    padding-top: 30px;
  }
}
.wallet_modal {
  .modal-dialog {
    max-width: 49rem;
    margin: 0 auto;
  }

  &_body {
    margin-top: 1rem;
    background: $themeClr2F;
    border: 2px solid $gray4D;
    border-radius: 2.3rem;
    padding: 2rem 3rem;

    @media (max-width: 1439px) {
      margin-top: 3rem;
    }

    &_detail {
      display: flex;
      justify-content: space-between;
    }
  }

  &_body_new {
    margin-top: 1rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.83);
    border-radius: 2.3rem;
    height: 24.3rem;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1439px) {
      margin-top: 3rem;
    }
  }

  img {
    margin-bottom: 2rem;
    width: 4.6rem;
    height: 4.6rem;
  }

  p {
    font-size: 1.6rem;
    color: $white;
    word-break: break-all;

    @media (max-width: 1439px) {
      font-size: 1.4rem;
    }
  }

  h6 {
    color: $white;
    font-weight: 400;

    @media (max-width: 1439px) {
      font-size: 1.6rem;
    }
  }

  .btn_sec {
    margin: 3rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .commonBtn {
      width: 100%;
    }

    span {
      font-size: 1.4rem;
      // white-space: nowrap;
    }
  }
}
