@import '../../../assets/_var.scss';

.notification_filter {
    // position: relative;
    padding-top: 3rem;

    &_search {
        position: relative;
      input[class*="form-control"] {
        border: 1px solid $borderClr3 !important;
      }
    }
    &_markbtn, &_deletebtn {
      padding: .8rem 2.2rem .8rem .8rem;
      border-radius: 2.6rem;
      color: $white;
      background-color: $themeClr2F;
      font-size: 1.4rem;
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: 1rem;
      }
    }
    &_action {
      display: flex;
      justify-content: flex-end;
      > * {
        margin-left: 2rem;
        
      }
      @media (max-width:767px) {
        justify-content: space-between;
      > * {
        margin: 0;
      }
      }
      @media (max-width:479px) {
      > * {
        // padding-right: .8rem;
        font-size: 1.2rem;
        svg {
          margin-right: .5rem;
          width: 2.8rem;
        }
      }
      }
    }
    
}