@import '../../../assets/_var.scss';
.partners {
    background-color: $themeClr2F;
    &_inner {
        ul {
            margin: 0 -1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        }
        li {
            padding: 3.5rem 1.5rem;
        }
    }
    @media (max-width:991px) {
        padding: 2.5rem 0;
        &_inner {
            ul {
                flex-wrap: wrap;
                justify-content: center;
                li {
                    padding: 1rem 1.5rem;
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                    text-align: center;
                }
            }
        }
    }
    @media (max-width:991px) {
        padding: 2.5rem 0;
        &_inner {
            ul {
                
                li {
                    
                    flex: 1 auto;
                    max-width: 100%;
                    img {
                        max-width: 20rem;
                        max-height: 3rem;
                    }
                }
            }
        }
    }
}