@import "../../../../assets/_var.scss";

.banner {
  background-color: green;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30rem;

  &_action {
    padding-bottom: 2.6rem;
    position: relative;
    z-index: 2;
    @media (max-width: 991px) {
      padding-bottom: 13rem;
    }
  }

  &_dropdown {
    display: inline-block;
    margin-left: 1rem;

    button {
      height: 5rem;
      width: 5rem;
      border-radius: 5rem;
      padding: 0 !important;
      display: inline-flex;
      justify-content: center !important;
      align-items: center;
      background-color: $themeClr2F;
      border: 0;

      &:after {
        content: none !important;
      }
    }

    :global {
      button {
        &.show {
          @media (min-width: 1280px) {
            background-color: $themeClr2F;
          }
          @media (max-width: 1279px) {
            background: transparent
              linear-gradient(180deg, $yellow 0%, $yellowDark 100%) 0% 0%
              no-repeat padding-box;
          }
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }

    > div {
      right: 0 !important;
      left: auto !important;
    }

    &:hover {
      // @media (min-width:1280px) {
      button {
        background: transparent
          linear-gradient(180deg, $yellow 0%, $yellowDark 100%) 0% 0% no-repeat
          padding-box;
      }

      svg {
        path {
          fill: $white;
        }
      }
      // }
    }
    &_shareButton {
      button {
        width: auto;
        padding: 0 !important;
        background: transparent !important;
        height: auto;
      }
    }
  }

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 991px) {
      justify-content: center;
    }
  }

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba($black, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    // z-index: -1;
  }
}
