@import "../../../assets/_var.scss";

.checkout_modal {
  :global {
    .modal-dialog {
      width: 100%;
      max-width: 48.9rem;

      .modal-content {
        padding: 0;

        .modal-header {
          align-items: center;
          padding: 4rem 0 0 4rem;
        }

        .commonModal-subtitle {
          display: flex;
          border-top: 2px solid rgba(255, 255, 255, 0.1);
          text-align: center;
          margin: 2rem 0;

          @media (max-width: 575px) {
            margin: 2.8rem 0;
          }

          > div {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            h4 {
              font-size: 1.8rem;
            }

            p {
              font-size: 1.4rem;
              font-weight: 400;
              color: $gray98;
            }
          }

          img {
            max-width: 5.6rem;
            width: 100%;
            height: 5.6rem;
            border-radius: 1rem;
            object-fit: cover;
          }
        }

        .modal-body {
          padding: 0 3rem;

          .sub_head {
            display: flex;
            align-items: center;
          }

          p {
            font-size: 2.6rem;
            font-weight: 600;

            @media (max-width: 575px) {
              font-size: 2rem;
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
  }
}

.inner_sec {
  display: flex;

  &_text {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;

    p {
      margin: 0;
      font-size: 1.6rem !important;
      font-weight: 300 !important;

      span {
        color: $yellowD7;
      }
    }
  }
}

.amount_sec {
  text-align: center;

  p {
    font-size: 1.6rem !important;
    font-weight: 300;
    margin: 0;

    @media (max-width: 1279px) {
      font-size: 1.2rem;
    }

    span {
      color: $yellowD7;
      font-weight: 400;
    }
  }

  &_bal {
    background: $themeClr;
    border-radius: 26px;
    opacity: 1;
    max-width: 21.7rem;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;

    h4 {
      font-size: 1.8rem;
      font-weight: 400;

      span {
        font-size: 3rem;
      }
    }
  }
}

.counter {
  background-color: $gray3D;
  display: flex;
  align-items: center;
  border-radius: 3.5rem;

  .counter_rate {
    border-radius: 50%;
    padding: 0;
    margin: 0 1rem;
    background-color: transparent;
    border: none;

    &:focus {
      background-color: transparent;
    }

    svg {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  :global {
    .form-control {
      text-align: center;
      color: $yellowD7;
      background-color: transparent;
    }
  }
}
.currencySelect {
  display: flex;
  justify-content: center;
  &_textWrap {
    font-size: 17px;
    color: #ffffff;
    &_static {
      color: #cf930f !important;
      margin-right: 5px;
    }
    &_dropDownWrap {
      display: inline-block;
      :global {
        .dropdown-menu {
          width: 10rem;
          background-color: #18181c;
        }
        .dropdown-toggle {
          background-color: transparent;
          border: 0px solid transparent;
          padding: 0 0 0 5px !important;
          font-size: 17px;
          font-weight: 600;
        }
      }
    }
  }
}
