@import "../../assets/_var.scss";

.notifications {
  position: relative;
  padding: 4rem 0 10rem;

  h1 {
    color: $white;
    font-weight: bold;
  }

  &_nav {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $borderClr2;

    li {
      a {
        padding: 1.5rem 5.5rem;
        color: $gray98;
        display: block;
        text-align: center;
        @extend %transition;
        position: relative;

        &:after {
          content: "";
          height: 2px;
          width: 100%;
          @extend %BgGradient;
          position: absolute;
          bottom: -1px;
          left: 0;
          opacity: 0;
        }

        &:hover {
          color: $white;
        }
      }

      :global {
        a.active,
        a:hover {
          color: $white;

          &:after {
            opacity: 1;
          }
        }
      }
    }
    @media (max-width: 575px) {
      li {
        flex: 0 0 50%;
        max-width: 50%;
        a {
          padding: 1.5rem;
        }
      }
    }
  }
  &_table {
    span {
      a {
        color: $white;
      }
    }
    video {
      height: 5.6rem;
      width: 5.6rem;
      margin: 0.7rem 1.5rem 0.7rem 0;
      border-radius: 1rem;
      object-fit: cover;
      display: inline-block;
      vertical-align: middle;
    }
    &_thumb {
      height: 5.6rem;
      width: 5.6rem;
      margin: 0.7rem 1.5rem 0.7rem 0;
      border-radius: 1rem;
      object-fit: cover;
      display: inline-block;
      vertical-align: middle;
    }
    &_act_mark,
    &_act_delete {
      display: inline-block;
      text-decoration: underline;
    }
    &_act_mark {
      color: $white900;
    }
    &_act_delete {
      color: $yellowD7;
    }
    &_act_divider {
      margin: 0 3rem;
      color: $gray3D;
    }
  }
}
