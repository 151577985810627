@import "../../../assets/_var.scss";

.header {
  margin-top: 3rem;
  h2 {
    color: $white900;
    margin-bottom: 2rem;
    line-height: 3.5rem;
    text-transform: capitalize;

    @media (max-width: 991px) {
      margin: 3rem 0;
    }
  }

  p {
    color: $gray98;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  .hl {
    background-color: #3e3e3e;
    height: 1px;
    width: 100%;
    margin: 2rem 0 3rem;
  }
}
