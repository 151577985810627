@import "../../../../assets/_var.scss";
.category_card {
  display: block;
  background: $themeClr2F;
  height: 100%;
  overflow: hidden;
  border-radius: 4.5rem;
  @extend %transition;
  position: relative;
  cursor: pointer;
  z-index: 1;
  &_thumb {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  h5 {
    @extend %transition;
    color: $gray98;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
    display: block;
    padding: 1.8rem 1rem;
    margin: 0;
  }
  &::before,
  &::after {
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 4.5rem;
    position: absolute;
    @extend %transition;
    pointer-events: none;
    max-width: 100%;
    left: 0;
    top: 0;
  }
  &::before {
    opacity: 0;
    z-index: -1;
    background: transparent
      linear-gradient(123deg, $yellowDark 0%, $yellow 100%) 0% 0% no-repeat
      padding-box;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
  &:after {
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 40%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 40%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 40%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
    opacity: 0.2;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
    &::after {
      opacity: 0;
    }
    h5 {
      color: $white900;
    }
  }
}
