@import "../../../assets/_var.scss";

.detailpage {
  margin: 6rem 0;

  @media (max-width: 991px) {
    margin: 3rem 0;
  }

  :global {
    .btn-properties {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $themeClr2F;
      border-radius: 2.5rem;
      padding: 1rem 2rem;
      width: 100%;
      margin-top: 2rem;
      color: $white900;
      font-size: 1.6rem;
    }
  }

  .vl {
    height: 5rem;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &_main_slider {
    // .slick-slider {
    // @media (min-width:1440px) {
    margin: 0 -1.5rem;
    // }
    // }
    :global {
      .img-nft {
        object-fit: cover;
        object-position: center;
        min-height: 555px;
        max-height: 563px;
        border-radius: 25px;
        width: 100%;
      }
      video {
        width: 100%;
        height: 604px;
        object-fit: cover;
        object-position: center;
        border-radius: 25px;
      }
    }
  }

  &_info {
    margin-top: 1.3rem;
    background-color: transparent !important;
    padding: 0;

    // @media(max-width: 991px) {
    //     padding: 0rem 0.7rem 2rem;
    // }

    h5 {
      display: none;
    }

    .detail_card {
      border-radius: 1rem;
      // margin-top: 1.3rem;
      background-color: transparent;
      cursor: pointer;
      overflow: hidden;
      border: 1px solid transparent;

      img,
      video {
        border-radius: 1rem;
        transition: 0.3s ease-in-out;
        width: 100%;
        transform: scale(1.01);
        min-height: 15.7rem;
        max-height: 15.7rem;
        object-fit: cover;
        object-position: center;

        @media (max-width: 767px) {
          min-height: 7.7rem;
          max-height: 7.7rem;
        }
      }
      &:hover {
        border: 1px solid $yellowDark3;
        img {
          transform: scale(1);
        }
      }
    }

    :global {
      .slick-slider {
        // @media (min-width:1440px) {
        margin: 0 -0.65rem;
        // }
      }
      .slick-slide {
        // padding: 0 0.5rem;
        // @media (min-width:1440px) {
        // margin: 0 -;
        padding: 0 0.65rem;
        // }

        &.slick-current {
          > div {
            > div {
              border: 1px solid $yellowDark3;
              img {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }

  .history {
    margin-top: 3.5rem;
    border-radius: 2.6rem;
    background-color: $themeClr2F;

    &_head {
      margin-top: 3rem;
      border-radius: 2.6rem;
      padding: 3rem 3rem 0 3rem;
      background-color: $themeClr2F;

      @media (max-width: 575px) {
        padding: 2rem 0 0;
      }

      h4 {
        color: $white900;
        margin: 0;
        // font-size: 2.6rem;
        padding: 0 3rem;

        @media (min-width: 1440px) {
          font-size: 2.6rem;
        }
      }

      hr {
        border-color: $borderClr2;
        margin: 3rem 0 0 0;
      }

      .inform_sec {
        background-color: $themeClr27;
        border-radius: 1rem;
      }
    }
  }

  &_inner {
    &_head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: $white;
      }

      &_info {
        display: flex;
        align-items: center;

        .dot_area {
          position: relative;
          background: $themeClr27;
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease all;

          &:hover {
            background-color: $yellowDark3;
          }

          @media (max-width: 1199px) {
            width: 4rem;
            height: 4rem;
          }

          :global {
            .dropdown-menu {
              right: 0 !important;
              left: auto !important;
            }

            .dropdown-toggle {
              background: transparent;
              border: transparent;
              border-radius: 50%;
              width: 5rem;
              height: 5rem;
              padding-left: 1.5rem;

              &::after {
                border: 0;
              }
            }
          }

          .common_dropdown {
            &_icon {
              width: 2rem !important;
              height: 2rem !important;

              svg {
                width: 2.3rem !important;
              }
            }
          }
        }

        &_feed {
          margin-right: 1rem;
          border: none;
          width: 7rem;
          max-width: 100%;
          height: 4rem;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 1rem;
          background-color: $themeClr27;
          border-radius: 1rem;

          @media (max-width: 1199px) {
            width: 6rem;
            height: 3rem;
          }

          &:hover,
          &:focus,
          &:active {
            background-color: $themeClr27 !important;
            border-color: none;
          }

          p {
            margin: 0;
            font-size: 1.8rem;

            @media (max-width: 1199px) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    &_info {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;

      @media (max-width: 575px) {
        width: 100%;
        justify-content: flex-start;
        gap: 1rem;
      }

      p {
        margin: 0;
        font-size: 1.4rem;
      }

      h3 {
        font-size: 1.8rem;
        color: $white;
        font-weight: 500;
      }
    }

    &_data {
      margin-top: 2rem;

      p {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }

      h4 {
        font-size: 1.8rem;
        color: $white;
        font-weight: 500;

        @media (max-width: 991px) {
          font-size: 1.6rem;
        }

        span {
          font-size: 1.4rem;
          font-weight: 300;
          color: $gray98;
        }
      }
      &_price {
        display: flex;

        li {
          &:nth-child(1) {
            padding-right: 3rem;
            position: relative;
            border-right: 0.2rem solid rgba(255, 255, 255, 0.1);
          }
          &:nth-child(2) {
            padding-left: 3rem;
          }
        }
      }
    }
  }

  &_content {
    background-color: $themeClr2F !important;
    border-radius: 2.5rem;
    padding: 2rem;

    &_inner {
      display: flex;
      align-items: center;
      padding-bottom: 2rem;

      p {
        margin: 0;
        font-size: 1.8rem;
        color: $yellowDark3;

        @media (max-width: 991px) {
          font-size: 1.6rem;
        }

        @media (max-width: 575px) {
          font-size: 1.4rem;
        }
      }

      img {
        width: 2rem;
        margin-right: 1rem;
      }
    }

    &_value {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 991px) {
        align-items: flex-start;
      }

      &_data {
        flex: 0 0 25%;

        @media (max-width: 991px) {
          flex: 0 0 28%;
        }

        @media (max-width: 575px) {
          flex: 0 0 28%;
        }

        a {
          color: $yellowDark3;
          text-decoration: underline;
          font-size: 1.8rem;

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }

        p {
          font-size: 1.4rem;
          margin-bottom: 0.5rem;
          line-height: 2.7;

          @media (max-width: 1439px) {
            line-height: 1.7;
          }

          @media (max-width: 575px) {
            font-size: 1.2rem;
          }
        }

        h4 {
          font-size: 1.8rem;
          color: $white;
          font-weight: 500;

          @media (max-width: 991px) {
            font-size: 1.6rem;
          }

          @media (max-width: 575px) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  &_address {
    margin-bottom: 2rem;
    border-radius: 2.5rem;
    padding: 2rem;
    background-color: $themeClr2F;

    @media (max-width: 575px) {
      padding: 1rem 2rem 0;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        p {
          margin: 0;
        }
      }

      p {
        font-size: 1.4rem;

        @media (max-width: 575px) {
          font-size: 1.2rem;
        }

        &:last-child {
          color: $white900;
          // margin-bottom: 0;
        }
      }
    }
  }

  .common-modal {
    :global {
      .model-content {
        padding: 0;
      }
    }
  }
  &_property {
    margin-top: 3rem;
    border-radius: 2.6rem;
    background-color: $themeClr2F;
    transition: all 0.3s ease;
    overflow: hidden;

    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 15%;
        p {
          font-size: 1.4rem;
          margin-bottom: 0;
        }
        h4 {
          font-size: 1.8rem;
          line-height: 2.7rem;
          color: $white900;
          font-weight: 600;
          margin-top: 0;
        }
      }
    }
  }
}

.popup_slider {
  :global {
    .modal-dialog {
      max-width: 139.6rem;

      .modal-content {
        height: 48.8rem;
        background-color: transparent;

        .modal-header {
          .btn-close {
            display: none;
          }
        }

        .modal-body {
          .mainSliderPopup {
            max-width: 58rem;
            width: 100%;
            margin: 0 auto;

            img,
            video {
              border-radius: 2rem;
              margin: 0 auto;
              height: 44rem;
              max-width: 57rem;
              width: 100%;
              object-fit: cover;
              object-position: center;

              @media (max-width: 767px) {
                height: auto;
              }
            }
          }

          .slick-dots {
            display: flex !important;
            justify-content: center;
            background: #2f2e36;
            position: absolute;
            width: 100%;
            top: 80%;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 3rem;
            max-width: 20rem;
            padding: 0.8rem 0;

            li {
              position: relative;

              button {
                margin-right: 1.5rem;
                width: 0.8rem;
                height: 0.8rem;
                font-size: 0;
              }

              &:before {
                content: "";
                position: absolute;
                width: 0.8rem;
                height: 0.8rem;
                background-color: $btngrey;
                border-radius: 50%;
                top: 0.8rem;
                left: 1rem;
              }
            }

            .slick-active {
              &:before {
                border: 3px solid $yellowD7;
                background-color: $black;
                width: 1.6rem;
                height: 1.6rem;
                top: 0.4rem;
                left: 0.6rem;
              }
            }
          }
        }
      }
    }
  }
}

// .mainSlider {
//     cursor: pointer;
// }
